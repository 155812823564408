import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { uploadFolder } from '../../utils/api';
import Utils from '../../../src/utils/utils';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

const ModalAthleteModelCard = ({ show, handleClose, data = {} }) => {
  const { formatCNPJCPF, formatTelefone } = Utils();
  const { t } = useTranslation();
  const componentRef = useRef();
  const uploadURL = uploadFolder();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const Logo = (props) => {
    const { schLogomarca } = JSON.parse(localStorage.getItem('school'));
    let domain = window.location.href.split('/')[2].split('.')[0];
    let logo = '';

    if (domain !== 'fensor' && domain !== 'www') {
      logo = domain.indexOf('localhost') !== -1 ? 'qa' : domain;

      if (props.home && logo == 'americamg') {
        logo = logo + '_home';
      }
    }

    return props.home && logo == 'americamg' ? (
      <img style={{ height: 100 }} src={`${uploadURL}upload/schools/logo/americamg_home_logo.png`} />
    ) : (
      <img style={{ height: 100 }} src={`${uploadURL}upload/schools/logo/${schLogomarca}`} />
    );
  };

  return (
    <div className='modal' id='carteirinha' style={show ? { display: 'flex', opacity: 1 } : null}>
      <div className='modal_content' style={{ width: 600 }}>
        <div className='modal_close' onClick={handleClose}>
          X
        </div>
        <h3 className='modal_title'>{t('ModelCard')}</h3>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <div ref={componentRef} style={{ width: 550 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                width: '100%',
                borderRadius: 8,
                marginTop: 20,
                padding: '10px 10px',
                background: 'linear-gradient(to right, #aaa, #eee)',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                  <div
                    style={{
                      display: 'flex',
                      // width: '46%',
                      width: 180,
                      height: 120,
                      background: String(data.foto).includes('null') ? 'white' : '',
                      borderRadius: 8,
                      border: '1px solid #aaa',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {!String(data.foto).includes('null') ? (
                      <img style={{ borderRadius: 7 }} src={data.foto} />
                    ) : (
                      <p className='uppercase' style={{ color: '#aaa', fontSize: 12, fontWeight: 'bold' }}>
                        {t('Photo')}
                      </p>
                    )}
                  </div>
                  <div style={{ width: '6%' }}></div>
                  <div style={{ display: 'flex', /* width: '46%', */ width: 180, height: 120, alignItems: 'center', justifyContent: 'center' }}>
                    <QRCode style={{ width: '97%', height: 118, borderRadius: 7 }} value={`${data.idStudent}-${data.id}`} />
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: 5 }}>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <p style={{ color: '#4F4F4F', fontSize: 12, fontWeight: 'bold' }}>{t('Student')}</p>
                    <div
                      style={{
                        background: 'white',
                        height: 20,
                        borderRadius: 12,
                        border: '1px solid #aaa',
                        fontSize: 11,
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {data.name}
                    </div>
                  </div>
                  <div style={{ marginTop: 5 }}>
                    <p style={{ color: '#4F4F4F', fontSize: 12, fontWeight: 'bold' }}>{t('Birth')}</p>
                    <div
                      style={{
                        background: 'white',
                        height: 20,
                        borderRadius: 12,
                        border: '1px solid #aaa',
                        fontSize: 11,
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {String(data.usuBirth).split('-').reverse().join('/')}
                    </div>
                  </div>
                  <div style={{ marginTop: 5 }}>
                    <p style={{ color: '#4F4F4F', fontSize: 12, fontWeight: 'bold' }}>{t('Classes')}</p>
                    <div
                      style={{
                        background: 'white',
                        height: 20,
                        borderRadius: 12,
                        border: '1px solid #aaa',
                        fontSize: 11,
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {data.nameClass}
                    </div>
                  </div>
                  <div style={{ marginTop: 5 }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: 'white',
                        height: 70,
                        borderRadius: 10,
                        border: '1px solid #aaa',
                      }}
                    >
                      <div style={{ height: 1, background: '#4F4F4F', width: '70%', marginTop: 30 }}></div>
                      <p style={{ color: '#4F4F4F', fontSize: 12, fontWeight: 'bold' }}>{t('President')}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    marginTop: 0,
                    justifyContent: 'space-evenly',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      /* width: '46%', */ width: 100,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img style={{ height: 100 }} src={`../../../img/clubes/qa_logo.png`} alt='Marca' />
                  </div>
                  <div style={{ width: '6%' }}></div>
                  <div
                    style={{
                      display: 'flex',
                      /* width: '46%', */ width: 100,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Logo />
                  </div>
                </div>
                {[
                  ['MonthName.January', 'MonthName.February', 'MonthName.March'],
                  ['MonthName.April', 'MonthName.May', 'MonthName.June'],
                  ['MonthName.July', 'MonthName.August', 'MonthName.September'],
                  ['MonthName.October', 'MonthName.November', 'MonthName.December'],
                ].map((months, i) => (
                  <div
                    key={`ModalAthleteModelCard_Months_${i}${months}`}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-evenly',
                      marginTop: i === 0 ? 0 : 5,
                    }}
                  >
                    {months.map((month, i) => (
                      <div
                        key={`${month}_${i}`}
                        style={{
                          display: 'flex',
                          width: '31%',
                          height: 60,
                          background: 'white',
                          borderRadius: 8,
                          border: '1px solid #aaa',
                        }}
                      >
                        {i !== 0 && <div style={{ width: '2%' }}></div>}
                        <div
                          style={{
                            display: 'flex',
                            width: '100%',
                            borderRadius: 8,
                          }}
                        >
                          <p
                            style={{
                              color: '#4F4F4F',
                              fontSize: 12,
                              marginTop: '50%',
                              marginLeft: 5,
                              fontWeight: 'bold',
                            }}
                          >
                            {t(month, { context: 'abbreviated' })}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '100px 0px',
                marginTop: 20,
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
                borderRadius: 8,
                background: 'linear-gradient(to right, #aaa, #eee)',
              }}
            >
              <p style={{ fontSize: 20 }}>
                <b>{data.schName}</b>
              </p>
              <div style={{ width: 1, height: 40, background: '#4F4F4F' }}></div>
              <p style={{ fontSize: 15 }}>{formatTelefone(String(data.schPhone))}</p>
              <p style={{ fontSize: 15 }}>{data.schEmail}</p>
              <div style={{ width: 1, height: 40, background: '#4F4F4F' }}></div>
              <p style={{ fontSize: 15 }}>{formatCNPJCPF(String(data.schCnpj))}</p>
            </div>
          </div>
          <button className='btn_card' style={{ marginTop: 20, width: '50%' }} onClick={handlePrint}>
            <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
            </svg>
            {t('PDFModelCard')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalAthleteModelCard;
