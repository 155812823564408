import React, { useEffect, useState } from 'react';
import SignatureCreditService from '../../services/SignatureCreditService';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { FaTrash } from 'react-icons/fa';
import PhoneMask from '../../components/inputs/PhoneMask';

const deliveryMethodOptions = [
  { label: 'E-mail', value: 'DELIVERY_METHOD_EMAIL' },
  { label: 'Telefone', value: 'DELIVERY_METHOD_SMS' },
];

const signatureActionsOptions = [
  { label: 'Assinar', value: 'SIGN' },
  { label: 'Aprovar', value: 'APPROVE' },
  { label: 'Reconhecer', value: 'RECOGNIZE' },
  { label: 'Testemunhar', value: 'SIGN_AS_A_WITNESS' },
  // { label: 'Acusar recebimento', value: 'ACKNOWLEDGE_RECEIPT' },
  // { label: 'Endossar em preto', value: 'ENDORSE_IN_BLACK' },
  // { label: 'Endossar em branco', value: 'ENDORSE_IN_WHITE' },
];
function SignatureContractModal({ onClose, onSuccessRequest, contract }) {
  const { t } = useTranslation();
  const [loadingSignatureRequest, setLoadingSignatureRequest] = useState(false);
  const [loadingContractSignatureData, setLoadingContractSignatureData] = useState(false);
  const [showMode, setShowMode] = useState(() => (contract.autentique_document_id ? 'edit' : 'create'));

  const [signers, setSigners] = useState([
    {
      mode: 'create',
      name: contract.athNomeRespFin || '',
      email: contract.athEmailRespFin || '',
      phone: contract.athPhoneRespFin || '',
      delivery_method: contract.athEmailRespFin ? 'DELIVERY_METHOD_EMAIL' : 'DELIVERY_METHOD_SMS',
      action: 'SIGN',
    },
  ]);

  useEffect(() => {
    if (showMode === 'create') return;
    setLoadingContractSignatureData(true);
    SignatureCreditService.getContractSignatureDetails(contract.id)
      .then((autentiqueDocuments) => {
        const formattedSigners = autentiqueDocuments.signatures
          .filter((signer) => signer.action)
          .map((signer) => {
            return {
              mode: 'edit',
              signed: Boolean(signer.signed),
              name: signer.name,
              email: signer.email,
              phone: signer.user?.phone?.slice(3) ?? '',
              delivery_method:
                signer.delivery_method === 'DELIVERY_METHOD_LINK' ? 'DELIVERY_METHOD_EMAIL' : signer.delivery_method,
              action: (signer.action || {}).name,
            };
          });
        setSigners(formattedSigners);
      })
      .finally(() => setLoadingContractSignatureData(false));
  }, [showMode]);
  const handleAddSigner = () => {
    setSigners([
      ...signers,
      {
        name: '',
        email: '',
        delivery_method: 'DELIVERY_METHOD_EMAIL',
        phone: '',
        reactKey: Math.random(),
        mode: 'create',
      },
    ]);
  };

  const handleSignerChange = (index, attribute, value) => {
    setSigners((prev) => {
      const copy = [...prev];
      copy[index][attribute] = value;
      return copy;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const signersPayload = signers.map((signer, index) => {
      const signerPayload = {
        name: signer.name,
        action: signer.action || 'SIGN',
        delivery_method: signer.delivery_method,
      };

      if (signer.delivery_method === 'DELIVERY_METHOD_EMAIL') {
        signerPayload.email = signer.email;
      }

      if (signer.delivery_method === 'DELIVERY_METHOD_SMS') {
        signerPayload.phone = `+55${signer.phone.replace(/[^\d]/g, '')}`;
      }

      return signerPayload;
    });
    try {
      setLoadingSignatureRequest(true);
      await SignatureCreditService.requestSignatures(signersPayload, contract.school_id, contract.id);
      onSuccessRequest();
    } catch (err) {
      let errorMessage = 'Houve um erro inesperado.';
      if (err.response.data) {
        errorMessage = err.response.data.message;
      }
      toast.error(errorMessage);
    } finally {
      setLoadingSignatureRequest(false);
    }
  };
  const handleChange = (e, index) => {
    e.target.setCustomValidity('');
    handleSignerChange(index, e.target.name, e.target.value);
  };
  return (
    <div className='modal' style={{ display: 'block', opacity: 1 }}>
      <div className='modal_content' style={{ margin: '0 auto', minHeight: 'min-content' }}>
        <div className='modal_close' onClick={onClose}>
          X
        </div>
        <h3 className='modal_title'>{t('SignatureContractModal.Title')}</h3>
        {loadingContractSignatureData ? (
          <span className='d-flex items-center' style={{ margin: '0 auto', gap: 10, marginTop: 20 }}>
            <Loading type='1' /> <p>Carregando dados de assinatura do contrato...</p>
          </span>
        ) : (
          <form onSubmit={handleSubmit} className='form_modal flex-1'>
            {signers.map((signer, index) => (
              <div key={signer.reactKey} className='group' style={{ gap: 10 }}>
                <div className='form_group'>
                  <label className='card_details_label'>{t('SignatureContractModal.Name')}</label>
                  <input
                    id={`full_name_input_${index}`}
                    disabled={loadingSignatureRequest || signer.mode === 'edit'}
                    required
                    value={signer.name}
                    className='form_control'
                    type='text'
                    placeholder={t('SignatureContractModal.Name')}
                    name='name'
                    pattern='^\S+(?:\s\S+)+$'
                    onChange={(e) => handleChange(e, index)}
                    onInvalid={(e) => {
                      if (e.target.validity.valueMissing || e.target.validity.patternMismatch)
                        e.target.setCustomValidity(t('SignatureContractModal.NameValidationError'));
                    }}
                  />
                </div>
                <div className='form_group' style={{ whiteSpace: 'nowrap' }}>
                  <label className='card_details_label'>{t('SignatureContractModal.DeliveryMethod')}</label>
                  <select
                    disabled={loadingSignatureRequest || signer.mode === 'edit'}
                    name='delivery_method'
                    onChange={(e) => handleChange(e, index)}
                    className='form_control select'
                    value={signer.delivery_method}
                  >
                    {deliveryMethodOptions.map((signatureType) => (
                      <option value={signatureType.value}>{signatureType.label}</option>
                    ))}
                  </select>
                </div>
                {signer.delivery_method === 'DELIVERY_METHOD_EMAIL' && (
                  <div className='form_group'>
                    <label className='card_details_label'>{t('SignatureContractModal.Email')}</label>
                    <input
                      disabled={loadingSignatureRequest || signer.mode === 'edit'}
                      required
                      className='form_control'
                      type='email'
                      placeholder='Email'
                      name={`email`}
                      value={signer.email}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </div>
                )}
                {signer.delivery_method === 'DELIVERY_METHOD_SMS' && (
                  <div className='form_group'>
                    <label className='card_details_label'>{t('SignatureContractModal.Phone')}</label>
                    <PhoneMask
                      disabled={loadingSignatureRequest || signer.mode === 'edit'}
                      required
                      className='form_control'
                      placeholder='Telefone'
                      name='phone'
                      value={signer.phone}
                      onChange={(value) => {
                        setSigners((prev) => {
                          const copy = [...prev];
                          copy[index].phone = value;
                          return copy;
                        });
                      }}
                    />
                  </div>
                )}
                <div className='form_group'>
                  <label className='card_details_label' style={{ whiteSpace: 'nowrap' }}>
                    {t('SignatureContractModal.SignAction')}
                  </label>
                  <select
                    disabled={loadingSignatureRequest || signer.mode === 'edit'}
                    name='action'
                    onChange={(e) => handleChange(e, index)}
                    className='form_control select'
                    value={signer.action}
                  >
                    {signatureActionsOptions.map((signatureType) => (
                      <option value={signatureType.value}>{signatureType.label}</option>
                    ))}
                  </select>
                </div>
                {signer.mode === 'edit' && signer.signed && (
                  <div className='align-self-center'>{t('SignatureContractModal.Signed')}</div>
                )}
                {signer.mode === 'edit' && !signer.signed && (
                  <div className='align-self-center'>{t('SignatureContractModal.Unsigned')}</div>
                )}
                <button
                  className='btn btn_card'
                  style={{ padding: 10, minWidth: 'fit-content' }}
                  title='Remover signatário'
                  disabled={signers.length === 1}
                  onClick={() => {
                    setSigners((prev) => prev.filter((_, i) => i !== index));
                  }}
                >
                  <FaTrash style={{ margin: 0 }} />
                </button>
              </div>
            ))}
            {!contract.autentique_document_id && (
              <>
                <button
                  className='btn btn_card'
                  type='button'
                  onClick={handleAddSigner}
                  disabled={loadingSignatureRequest}
                >
                  {t('SignatureContractModal.AddSigner')}
                </button>

                <button className='btn btn_card' type='submit' disabled={loadingSignatureRequest}>
                  {loadingSignatureRequest && <Loading type='1' />} {t('SignatureContractModal.RequestSigns')}
                </button>
              </>
            )}
          </form>
        )}
      </div>
    </div>
  );
}

export default SignatureContractModal;
