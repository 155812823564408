import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Badge from 'react-bootstrap/Badge';
import { Trans, useTranslation } from 'react-i18next';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Utils, { getErrorMessageFromFileResponse, getErrorMessageFromResponse } from '../../../src/utils/utils';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import ButtonRounded from '../../components/buttons/ButtonRounded';
import MoneyMask from '../../components/inputs/MoneyMask';
import Footer from '../../components/school/Footer';
import FinancialTable from '../../components/tables/FinancialTable';
import WhatsAppComponent from '../../components/whatsapp/WhatsAppComponent';
import { attachDocument } from '../../services/FinancialService';
import { auth, client, getAsaasConfigSessionSchool, payment, receiveInCash } from '../../services/PaymentService';
import { getUserPhoneNumbers, sendWhatsAppMessageWithFileAlt } from '../../services/WhatsAppService';
import api from '../../services/api';
import '../../style_whatsapp.css';
import Api from '../../utils/api';
import { calcFinanceValue, calcValueToReceive, discoverBillCase, onBillCase } from '../../utils/utils-financial';
import { validateCNPJ, validateCPF } from '../../utils/validators';
import ModalFinanceStakeholder from '../../views/ModalFinanceStakeholder';

const Financial = (props) => {
  const { t, i18n } = useTranslation();

  const alertRef = React.useRef(null);

  // indexs
  const [id, setID] = useState(null);
  const [school_id, setSchoolID] = useState('');
  const [user_id, setUserID] = useState('');
  const [configAsaas, setConfigAsaas] = useState({});
  const [financeType, setFinanceType] = useState(undefined);

  // inputs modal finaceiro
  const [modalStudentClientFornecedor, setModalStudentClientFornecedor] = useState('');
  const [modalType, setModalType] = useState('');
  const [modalCountDescription, setModalCountDescription] = useState('');
  const [modalPayment, setModalPayment] = useState('');
  const [modalModo, setModalModo] = useState('');
  const [modalDueDate, setModalDueDate] = useState('');
  const [modalValue, setModalValue] = useState(null);
  const [modalAmount, setModalAmount] = useState('');
  const [modalReceived, setModalReceived] = useState(false);
  const [modalDatePayment, setModalDatePayment] = useState('');
  const [modalDocument, setModalDocument] = useState('');
  const [modalObservation, setModalObservation] = useState('');
  const [modalQuota, setModalQuota] = useState('');
  const [modalIDAsaas, setModalIDAsaas] = useState('');
  const [paymentBankSlipUrl, setPaymentBankSlipUrl] = useState(null);

  const [modalDiscountType, setModalDiscountType] = useState('NONE');
  const [modalDiscount, setModalDiscount] = useState(null);
  const [modalLateInterestType, setModalLateInterestType] = useState('NONE');
  const [modalLateInterest, setModalLateInterest] = useState(null);
  const [modalLateFeeType, setModalLateFeeType] = useState('NONE');
  const [modalLateFee, setModalLateFee] = useState(null);

  const [modalValueReceived, setModalValueReceived] = useState(null);

  // carregamentos
  const [financials, setFinancials] = useState([]);
  const [modalStudentsClientsFornecedors, setModalStudentsClientsFornecedors] = useState([]);
  const [numberDataPerPaginate] = useState(50);

  // filtros
  const [filterStudentClientFornecedor, setFilterStudentClientFornecedor] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterPayment, setFilterPayment] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterDueDateIni, setFilterDueDateIni] = useState(new Date().toISOString().substr(0, 8) + '01');
  const [filterDueDateEnd, setFilterDueDateEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().substr(0, 10)
  );
  const [display, setDisplay] = useState({});
  const [displayClientFornecedor, setDisplayClientFornecedor] = useState(false);
  const [displayModalMessage, setDisplayModalMessage] = useState({});
  const [filteredFinancials, setFilteredFinancials] = useState([]);
  const [sortIcon, setSortIcon] = useState({ column: '', state: 'up' });
  const [filterName, setFilterName] = useState('');
  const [filterStudentClass, setFilterStudentClass] = useState('');
  const [filterStudentBirthYear, setFilterStudentBirthYear] = useState('');
  const [filterDateType, setFilterDateType] = useState('OVERDUE');

  // process
  const [process, setProcess] = useState(false);
  const [sendSave, setSendSave] = useState(false);
  const [isAllowedReceiveInCash, setIsAllowedReceiveInCash] = useState(false);

  // messages
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [message, setMessage] = useState('teste tradução');
  const [warningFaturamento, setWarningFaturamento] = useState(false);
  const [inforFaturamento, setInfoFaturamento] = useState(false);
  const [confirmReceiptType, setConfirmReceiptType] = useState(null);
  const modalValueRef = useRef(null);

  //WhatsApp
  const [detail, setDetail] = useState(null);
  const [user, setUser] = useState(null);

  // outros
  const [dirDoc, setDirDoc] = useState(false);
  const [valueR, setValueR] = useState(0);
  const [valueD, setValueD] = useState(0);
  const [countR, setCountR] = useState(0);
  const [countD, setCountD] = useState(0);
  const [currentTab, setCurrentTab] = useState('receitas');
  const [classes, setClasses] = useState([]);

  const [showModalCharge, setShowModalCharge] = useState(false);

  const [standPlans, setStandPlans] = useState([]);

  // libraries
  const baseURL = Api();
  const { scrollTop, validaCPF, validaCNPJ, compareStrings, compareDate } = Utils();
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();
  const modalScroll = useRef();
  const whatsAppComponentRef = useRef();

  useEffect(() => {
    async function getSession() {
      const usuSession = JSON.parse(await localStorage.getItem('user'));
      const schoolSession = JSON.parse(await localStorage.getItem('school'));
      const companySession = JSON.parse(await localStorage.getItem('company'));

      setUserID(usuSession.id);
      setSchoolID(schoolSession.id);
      getFinancials({ school: schoolSession.id, invalidInitialValue: false });

      setConfigAsaas(getAsaasConfigSessionSchool(schoolSession, companySession));

      /*   const res = await axios.get(`${baseURL}standardized-plans/invoice/${schoolSession.id}`);
      setStandPlans(res.data); */
    }

    getSession();
    window.addEventListener('keydown', closeModalOnEscape);
    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
  }, []);

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') {
      closeModal();
      whatsAppComponentRef.current.closeModal();
      closeModalClientFornecedor();
    }
  };

  const getFinancials = async ({ school, invalidInitialValue }) => {
    let student = null;

    if (
      props.location.state &&
      props.location.state.student &&
      props.location.state.financials &&
      invalidInitialValue !== null &&
      !invalidInitialValue
    ) {
      student = props.location.state.student;

      edit(props.location.state.financials);
      setFilterStudentClientFornecedor(student.name);
      setFilterType('Receita');
      setFilterPayment('');
      setFilterStatus('N');
      setFilterDueDateIni('');
      setFilterDueDateEnd('');
      setFilterStudentClass('');
      setFilterStudentBirthYear('');
      setFilterDateType('');
    }

    setProcess(true);

    const res = await axios.post(baseURL + 'get-financials', {
      school_id: school,
      filterStudentClientFornecedor,
      filterType,
      filterPayment,
      filterStatus,
      filterStudentClass,
      filterStudentBirthYear,
      filterDueDateIni: student === null ? filterDueDateIni : '',
      filterDueDateEnd: student === null ? filterDueDateEnd : '',
      filterDateType,
      user_id_student: student === null ? '' : student.user_id,
      pagination: { offset: 0, limit: numberDataPerPaginate },
    });

    setDirDoc(res.data.dirDoc);
    setFinancials([...res.data.financials]);
    setClasses(res.data.classes);

    setModalStudentsClientsFornecedors(res.data.studentsClientsFornecedors);

    setCountR(res.data.financials.filter((fin) => fin.type === 'Receita').length);
    setCountD(res.data.financials.filter((fin) => fin.type === 'Despesa').length);

    const count = res.data.financials.length == 0 ? 0 : Math.ceil(res.data.countRows / res.data.financials.length);
    for (let i = 1; i < count; i++) {
      const res = await axios.post(baseURL + 'get-financials', {
        school_id: school,
        filterStudentClientFornecedor,
        filterType,
        filterPayment,
        filterStatus,
        filterDueDateIni,
        filterDueDateEnd,
        filterDateType,
        pagination: { offset: i * numberDataPerPaginate, limit: numberDataPerPaginate },
      });

      setFinancials((prev) => [...prev, ...res.data.financials]);

      const conR = res.data.financials.filter((fin) => fin.type === 'Receita').length;
      const conD = res.data.financials.filter((fin) => fin.type === 'Despesa').length;

      setCountR((prev) => prev + conR);
      setCountD((prev) => prev + conD);
    }

    setFinancials((current) => {
      calcTotals(current);
      return current;
    });
    setProcess(false);
  };

  useEffect(() => {
    const { column, state } = sortIcon;
    const compareFunction = column === 'client' ? compareStrings : compareDate;

    const newFilteredFinancials = financials.filter((financial) => {
      const f = financial.client ? financial.client : financial.student;
      return filterName === '' || f.toLowerCase().includes(filterName.toLowerCase().trim());
    });

    state === 'up'
      ? newFilteredFinancials.sort((a, b) =>
          compareFunction(a[column] ? a[column] : a.student, b[column] ? b[column] : b.student)
        )
      : newFilteredFinancials.sort((b, a) =>
          compareFunction(a[column] ? a[column] : a.student, b[column] ? b[column] : b.student)
        );

    setFilteredFinancials(newFilteredFinancials);
  }, [financials, filterName, sortIcon]);

  const calcTotals = async (financials) => {
    setValueR(
      financials
        .filter((fin) => fin.type === 'Receita')
        .map(calcValueToReceive)
        .reduce((acc, current) => acc + current.valueCalculated, 0)
    );

    setValueD(
      financials
        .filter((fin) => fin.type === 'Despesa')
        .map(calcValueToReceive)
        .reduce((acc, current) => acc + current.valueCalculated, 0)
    );
  };

  const search = (e) => {
    e.preventDefault();
    setFilteredFinancials([]);
    getFinancials({ school: school_id, invalidInitialValue: true });
  };

  const newClientFornecedor = () => {
    setDisplayClientFornecedor(true);
  };

  const closeModalClientFornecedor = () => {
    setDisplayClientFornecedor(false);
  };

  const newCount = () => {
    discoverCase(null);
    setError(false);
    setSuccess(false);
    setWarningFaturamento(false);
    setInfoFaturamento(false);
    setDeleted(false);
    setSendSave(false);

    setID(null);
    setModalStudentClientFornecedor('');
    setModalType('');
    setModalCountDescription('');
    setModalPayment('');
    setModalModo('');
    setModalDueDate('');
    setModalValue('');
    setModalDiscountType('NONE');
    setModalDiscount(null);
    setModalLateInterestType('NONE');
    setModalLateInterest(null);
    setModalLateFeeType('NONE');
    setModalLateFee(null);
    setModalValueReceived(undefined);
    setModalAmount('');
    setModalReceived('N');
    setModalDatePayment('');
    setModalObservation('');
    setModalQuota('');
    setModalDocument('');
    setIsAllowedReceiveInCash(false);
    setPaymentBankSlipUrl(null);

    scrollTop(modalScroll);

    setDisplay({
      display: 'flex',
      opacity: 1,
    });
  };

  const closeModal = () => {
    setDisplay({});
  };

  useEffect(() => {
    if (error || success || warningFaturamento || inforFaturamento || sendSave || deleted)
      setDisplayModalMessage({
        display: 'flex',
        opacity: 1,
      });
  }, [error, success, warningFaturamento, inforFaturamento, sendSave, deleted]);

  const showConfirmReceiptType = (financial) => {
    setConfirmReceiptType(
      <SweetAlert
        info
        title='Como deseja o recibo?'
        showCloseButton
        closeOnClickOutside
        onCancel={() => setConfirmReceiptType(null)}
        customButtons={
          <>
            <button className='btn btn-lg btn-info mr-1' onClick={() => recibo(financial, false)}>
              Recibo simples
            </button>
            <button className='btn btn-lg btn-info ml-1' onClick={() => recibo(financial, true)}>
              Recibo duplo
            </button>
          </>
        }
      />
    );
  };

  const getReceiptUrl = (financial, isDouble = false) => {
    const queries = new URLSearchParams({ language: i18n.resolvedLanguage, ...(isDouble ? undefined : { single: 1 }) });
    return `${Api()}financial/recibo/${financial.id}/.pdf?${queries}`;
  };

  const recibo = (financial, isDouble = false) => {
    setConfirmReceiptType(null);
    const url = getReceiptUrl(financial, isDouble);
    window.open(url, '_blank');
  };

  const edit = (financial) => {
    setPaymentBankSlipUrl(
      ['BOLETO', 'CREDIT_CARD'].includes(financial.payment_type) ? financial.payment_bankSlipUrl : null
    );

    const payment =
      financial.payment_id_asaas && !financial.payment.includes('_INTEGRATED')
        ? financial.payment + '_INTEGRATED'
        : financial.payment;

    discoverCase(financial);
    setError(false);
    setSuccess(false);
    setWarningFaturamento(false);
    setInfoFaturamento(false);
    setDeleted(false);
    setSendSave(false);
    setID(financial.id);
    setModalStudentClientFornecedor(
      financial.client_fornecedor_id === null
        ? {
            idUser: financial.user_id_student,
            name: financial.student,
            usuCPF: financial.usuCPF,
            usuPhone: financial.usuPhone,
          }
        : {
            id: financial.client_fornecedor_id,
            name: financial.client,
            cpf_cnpj: financial.cpf_cnpj,
            usuPhone: financial.phone,
          }
    );
    setModalType(financial.type);
    setModalCountDescription(financial.countDescription);
    setModalPayment(payment);
    setModalModo(financial.mode);
    setModalDueDate(financial.dueDate);
    setModalValue(financial.value);
    setModalDiscountType(financial.discount_type === null ? 'NONE' : financial.discount_type);
    setModalDiscount(financial.discount);
    setModalLateInterestType(financial.late_interest_type === null ? 'NONE' : financial.late_interest_type);
    setModalLateInterest(financial.late_interest);
    setModalLateFeeType(financial.late_fee_type === null ? 'NONE' : financial.late_fee_type);
    setModalLateFee(financial.late_fee);
    setModalValueReceived(
      financial.valueReceived !== null && financial.valueReceived !== undefined ? +financial.valueReceived : null
    );
    setModalAmount(financial.amount);
    setModalReceived(financial.received);
    setModalDatePayment(financial.datePayment == null ? '' : financial.datePayment);
    setModalObservation(financial.observation);
    setModalQuota(financial.quota);
    setModalIDAsaas(financial.payment_id_asaas);
    setModalDocument(financial.document !== null ? dirDoc + '/' + financial.document : '');

    setIsAllowedReceiveInCash(false);

    scrollTop(modalScroll);
    setDisplay({
      display: 'flex',
      opacity: 1,
    });
  };

  const showMessageInfo = () => {
    setError(false);
    setSuccess(false);
    setWarningFaturamento(false);
    setInfoFaturamento(true);
    scrollTop(modalScroll);
  };

  const handleValid = async (e) => {
    e.preventDefault();
    setError(false);
    setSuccess(false);
    setWarningFaturamento(false);

    if (
      modalValue === '' ||
      modalValue === null ||
      modalValue === undefined ||
      modalValue === 0 ||
      modalValue === '0,00' ||
      modalValue === '0.00'
    ) {
      modalValueRef.current.focus();
      // set html form validation message
      modalValueRef.current.setCustomValidity('Valor é obrigatório');
      modalValueRef.current.reportValidity();
      setTimeout(() => {
        modalValueRef.current.setCustomValidity('');
      }, 3000);
      return;
    }

    
    
 
    if (modalType === 'Receita' && modalPayment.includes('_INTEGRATED')) {
      const cpf_cnpj =
        modalStudentClientFornecedor.idUser !== undefined
          ? modalStudentClientFornecedor.usuCPF
          : modalStudentClientFornecedor.cpf_cnpj;

      if (cpf_cnpj.length === 14 && !validateCNPJ(cpf_cnpj, i18n.language)) {
        setError(true);
        setMessage('SystemMessage.Fail.InvalidCNPJ');
      } else if (cpf_cnpj.length === 11 && !validateCPF(cpf_cnpj, i18n.language)) {
        setError(true);
        setMessage('SystemMessage.Fail.InvalidCPF');
      } else if (cpf_cnpj === null) {
        setError(true);
        setMessage('SystemMessage.Fail.CPFOrCNPJRegisteredRequired');
      } else if (id == null && new Date().toISOString().substr(0, 10) > modalDueDate) {
        setError(true);
        setMessage('SystemMessage.Fail.DueDateInvalid');
      } else if (id !== null && isAllowedReceiveInCash) {
        handleReceiveInCash();
      } else if (id !== null) {
        handleSubmitSaveUpdate();
      } else {
        setWarningFaturamento(true);
      }
    } else if (isAllowedReceiveInCash) {
      handleReceiveInCash();
    } else {
      handleSubmitSaveUpdate();
    }

    scrollTop(modalScroll);
  };

  const handleSubmitSaveUpdate = async () => {
    let data = {};
    let endpoint = '';
    let value = modalValue;
    const userIdBaixa = modalReceived === 'S' ? JSON.parse(localStorage.getItem('user'))?.id : null;

    if (id === null) {
      endpoint = 'save-financial-school';
      data = {
        school_id,
        user_id,
        user_id_baixa: userIdBaixa,
        client_fornecedor_id:
          modalStudentClientFornecedor.idUser !== undefined ? null : modalStudentClientFornecedor.id,
        user_id_student: modalStudentClientFornecedor.idUser !== undefined ? modalStudentClientFornecedor.idUser : null,
        type: modalType,
        countDescription: modalCountDescription,
        mode: modalModo,
        payment: modalPayment,
        dueDate: modalDueDate,
        value,
        amount: modalAmount,
        received: modalReceived,
        datePayment: modalDatePayment,
        observation: modalObservation,
        discount_type: modalDiscountType === 'NONE' ? null : modalDiscountType,
        discount: modalDiscount || null,
        late_interest_type: modalLateInterestType === 'NONE' ? null : modalLateInterestType,
        late_interest: modalLateInterest || null,
        late_fee_type: modalLateFeeType === 'NONE' ? null : modalLateFeeType,
        late_fee: modalLateFee || null,
        valueReceived: modalValueReceived ?? null,
      };
    } else {
      endpoint = 'update-financial-school';
      data = {
        id,
        school_id,
        user_id_baixa: userIdBaixa,
        client_fornecedor_id:
          modalStudentClientFornecedor.idUser !== undefined ? null : modalStudentClientFornecedor.id,
        user_id_student: modalStudentClientFornecedor.idUser !== undefined ? modalStudentClientFornecedor.idUser : null,
        type: modalType,
        countDescription: modalCountDescription,
        mode: modalModo,
        payment: modalPayment,
        dueDate: modalDueDate,
        value,
        amount: id === null ? modalAmount : modalAmount || undefined,
        received: modalReceived,
        datePayment: modalDatePayment,
        observation: modalObservation,
        discount_type: modalDiscountType === 'NONE' ? null : modalDiscountType,
        discount: modalDiscount || null,
        late_interest_type: modalLateInterestType === 'NONE' ? null : modalLateInterestType,
        late_interest: modalLateInterest || null,
        late_fee_type: modalLateFeeType === 'NONE' ? null : modalLateFeeType,
        late_fee: modalLateFee || null,
        valueReceived: modalValueReceived ?? null,
      };
    }
    setSendSave(true);
    const res = await axios.post(baseURL + endpoint, data);
    setSendSave(false);
    setSuccess(true);
    setID(res.data.id);
    setMessage(res.data.messageKey);
    getFinancials({ school: school_id, invalidInitialValue: true });

    scrollTop(modalScroll);
    closeModal();
  };

  const handleDeleteCount = async () => {
    setError(false);
    setSuccess(false);
    setInfoFaturamento(false);
    setWarningFaturamento(false);

    setDeleted(true);
    scrollTop(modalScroll);
    const { data } = await axios.post(baseURL + 'delete-financial-school', { id });

    setFinancials([]);
    getFinancials({ school: school_id, invalidInitialValue: true });

    closeModal();
    setDeleted(false);
    setSuccess(true);
    setMessage(data.message);
  };

  const handleFaturar = async () => {
    const cpf_cnpj =
      modalStudentClientFornecedor.idUser !== undefined
        ? modalStudentClientFornecedor.usuCPF
        : modalStudentClientFornecedor.cpf_cnpj;

    if (cpf_cnpj === null) {
      setError(true);
      setMessage('SystemMessage.Fail.CPFOrCNPJRegisteredRequired');
    } else if (cpf_cnpj.length === 14 && !validaCNPJ(cpf_cnpj)) {
      setError(true);
      setMessage('SystemMessage.Fail.InvalidCNPJ');
    } else if (cpf_cnpj.length !== 14 && !validaCPF(cpf_cnpj)) {
      setError(true);
      setMessage('SystemMessage.Fail.InvalidCPF');
    } else if (
      configAsaas.cnpj &&
      configAsaas.password &&
      configAsaas.token_assas &&
      configAsaas.wallet &&
      configAsaas.utilizaFaturamento === 'S'
    ) {
      setError(false);
      setSuccess(false);
      setWarningFaturamento(false);
      setInfoFaturamento(false);
      setSendSave(true);

      const resAuth = await auth(configAsaas.cnpj, configAsaas.password, configAsaas.loginEmail);

      if (resAuth.status == 200) {
        if (resAuth.connection == undefined) {
          if (resAuth.data.token != undefined) {
            // envia o cliente para obter o customer_id

            const customer =
              modalStudentClientFornecedor.idUser !== undefined
                ? {
                    cpfCnpj: modalStudentClientFornecedor.athCPFRespFin,
                    name: modalStudentClientFornecedor.athNomeRespFin + '(' + modalStudentClientFornecedor.name + ')',
                    email: modalStudentClientFornecedor.athEmailRespFin,
                    phone: modalStudentClientFornecedor.athPhoneRespFin,

                    postalCode: modalStudentClientFornecedor.athCep,
                    address: modalStudentClientFornecedor.athLogradouro,
                    addressNumber: modalStudentClientFornecedor.athNumero,
                    province: modalStudentClientFornecedor.athBairro,
                  }
                : {
                    cpfCnpj: modalStudentClientFornecedor.cpf_cnpj,
                    name: modalStudentClientFornecedor.name,
                    email: modalStudentClientFornecedor.email,
                    phone: modalStudentClientFornecedor.phone,

                    postalCode: modalStudentClientFornecedor.zipCode,
                    address: modalStudentClientFornecedor.streetName,
                    addressNumber: modalStudentClientFornecedor.streetNumber,
                    complement: modalStudentClientFornecedor.complement,
                    province: modalStudentClientFornecedor.neighborhood,
                  };

            const resCli = await client(
              customer.cpfCnpj,
              customer.name,
              customer.email,
              customer.phone,
              configAsaas.system_id,
              resAuth.data.user_id,
              configAsaas.token_assas,
              '',
              {
                postalCode: customer.postalCode,
                address: customer.address,
                addressNumber: customer.addressNumber,
                complement: customer.complement,
                province: customer.province,
              }
            );

            if (resCli.status == 200 && typeof resCli.data.message === 'string') {
              let endpoint = '';
              let data = '';
              let value = modalValue;

              if (modalAmount === 1) {
                endpoint =
                  modalPayment === 'Boleto_INTEGRATED' ? 'payment-boleto-vista' : 'payment-card-cred-vista-invoice-url';

                data = {
                  billingType: modalPayment === 'Boleto_INTEGRATED' ? 'BOLETO' : 'CREDIT_CARD',
                  customer: resCli.data.data.customer,
                  value: value,
                  dueDate: modalDueDate,
                  description: modalCountDescription,
                  installmentCount: '',
                  installmentValue: '',
                  postalService: false,
                  client_id: resCli.data.data.id,
                  token_assas: configAsaas.token_assas,
                  financial: {
                    school_id,
                    user_id,
                    client_fornecedor_id:
                      modalStudentClientFornecedor.idUser !== undefined ? null : modalStudentClientFornecedor.id,
                    user_id_student:
                      modalStudentClientFornecedor.idUser !== undefined ? modalStudentClientFornecedor.idUser : null,
                    type: modalType,
                    countDescription: modalCountDescription,
                    mode: modalModo,
                    payment: modalPayment.replace('_INTEGRATED', ''),
                    dueDate: modalDueDate,
                    amount: modalAmount,
                    received: modalReceived,
                    datePayment: modalDatePayment,
                    observation: modalObservation,
                  },
                };
              } else {
                endpoint =
                  modalPayment === 'Boleto_INTEGRATED'
                    ? 'payment-boleto-parcelado'
                    : 'payment-card-cred-parcelado-invoice-url';

                data = {
                  billingType: modalPayment === 'Boleto_INTEGRATED' ? 'BOLETO' : 'CREDIT_CARD',
                  customer: resCli.data.data.customer,
                  dueDate: modalDueDate,
                  description: modalCountDescription,
                  installmentCount: modalAmount,
                  installmentValue: value,
                  postalService: false,
                  client_id: resCli.data.data.id,
                  token_assas: configAsaas.token_assas,
                  financial: {
                    school_id,
                    user_id,
                    client_fornecedor_id:
                      modalStudentClientFornecedor.idUser !== undefined ? null : modalStudentClientFornecedor.id,
                    user_id_student:
                      modalStudentClientFornecedor.idUser !== undefined ? modalStudentClientFornecedor.idUser : null,
                    type: modalType,
                    countDescription: modalCountDescription,
                    mode: modalModo,
                    payment: modalPayment.replace('_INTEGRATED', ''),
                    dueDate: modalDueDate,
                    amount: modalAmount,
                    received: modalReceived,
                    datePayment: modalDatePayment,
                    observation: modalObservation,
                  },
                };
              }

              if (configAsaas.percentual) {
                data.split = [
                  {
                    walletId: configAsaas.wallet,
                    percentualValue: configAsaas.percentual,
                  },
                ];
              } else if (configAsaas.valor) {
                data.split = [
                  {
                    walletId: configAsaas.wallet,
                    fixedValue: configAsaas.valor,
                  },
                ];
              }

              if (modalDiscount) {
                data.discount = { value: modalDiscount, dueDateLimitDays: 0, type: modalDiscountType };
              }

              if (modalLateInterest) {
                data.interest = { value: modalLateInterest, type: modalLateInterestType };
              }

              if (modalLateFee) {
                data.fine = { value: modalLateFee, type: modalLateFeeType };
              }

              // envia pagamento
              const resPayment = await payment(endpoint, data);

              const paymentResponse = resPayment.data.data ? resPayment.data.data : resPayment.data;

              if (paymentResponse.paymentResponse) {
                if (paymentResponse.request.status == 'CONFIRMED' || paymentResponse.request.status == 'PENDING') {
                  setError(false);
                  setSuccess(true);
                  setWarningFaturamento(false);
                  setMessage('SystemMessage.Success.ScheduledBillingSuccessfully');

                  setSendSave(false);
                  getFinancials({ school: school_id, invalidInitialValue: true });

                  closeModal();
                } else {
                  setError(true);
                  setSuccess(false);
                  setWarningFaturamento(true);
                  setSendSave(false);
                  setMessage('SystemMessage.Fail.PaymentNotApproved');
                }
              } else if (paymentResponse.message != undefined) {
                setError(true);
                const msgs = getErrorMessageFromResponse(paymentResponse.message);

                setMessage(msgs);
                setSendSave(false);
              } else if (paymentResponse.erro != undefined) {
                setError(true);
                setMessage('SystemMessage.Fail.CommunicationProblemServer');
                console.log('Nao foi possivel validar o pagamento.');
                setWarningFaturamento(true);
                setSendSave(false);
              }
            } else if (resCli.status == 200 && Array.isArray(resCli.data.message)) {
              setError(true);
              let message = [
                'Erro ao validar as informações do cliente.',
                ...resCli.data.message
                  .map(({ field, message }) => {
                    switch (field) {
                      case 'email':
                        return `- Endereço de e-mail inválido: ${customer.email}`;

                      case 'phone':
                        return `- Telefone inválido: ${customer.phone}`;

                      case 'address':
                        return `- Endereço maior do que 55 caracteres: ${[
                          customer.postalCode,
                          customer.address,
                          customer.complement,
                          customer.province,
                        ]
                          .filter((f) => f)
                          .join(', ')}`;

                      default:
                        return `\n- ${message}`;
                    }
                  })
                  .filter((f) => f),
              ].join('\n');
              setMessage(message);
              setWarningFaturamento(true);
            } else {
              setError(true);
              setMessage('SystemMessage.Fail.CommunicationProblemServer');
              console.log('Nao foi possivel enviar o cliente.');
              setWarningFaturamento(true);
              setSendSave(false);
            }
          } else {
            setError(true);
            setMessage('SystemMessage.Fail.CommunicationProblemServer');
            console.log('Autenticação com a API inválida.');
            setWarningFaturamento(true);
            setSendSave(false);
          }
        } else {
          setError(true);
          setMessage('SystemMessage.Fail.CommunicationProblemServer');
          console.log('Erro ao comunicar com o servidor.');
          setWarningFaturamento(true);
          setSendSave(false);
        }
      } else {
        setError(true);
        setMessage('SystemMessage.Fail.CommunicationProblemServer');
        console.log('Erro ao comunicar com o servidor.');
        setWarningFaturamento(true);
        setSendSave(false);
      }
    } else {
      setError(true);
      setMessage('SystemMessage.Fail.SchoolMustBeParameterized');
      console.log('Erro ao comunicar com o servidor.');
      setWarningFaturamento(false);
      setSendSave(false);
    }

    scrollTop(modalScroll);
  };

  const handleDeleteCountFaturamento = async () => {
    setError(false);
    setSuccess(false);
    setInfoFaturamento(false);

    const data = {
      idAsaas: modalIDAsaas,
      token_assas: configAsaas.token_assas,
      financial_id: id,
    };

    // envia pagamento
    setSendSave(true);
    const resp = await payment('delete-payment-e-financial-school', data);
    setSendSave(false);

    if (resp.data.deleted) {
      setSuccess(true);
      setMessage('SystemMessage.Success.AccountAndBillingDeleted');
      setFinancials([]);
      getFinancials({ school: school_id, invalidInitialValue: true });

      closeModal();
    } else {
      setError(true);
      setMessage('SystemMessage.Fail.BillNotDeleted');
      setInfoFaturamento(true);
    }

    scrollTop(modalScroll);
  };

  const allowReceiveInCash = () => {
    if (modalReceived == 'N') {
      setIsAllowedReceiveInCash(true);
      setModalReceived('S');
    }
  };

  const handleReceiveInCash = async () => {
    if (
      configAsaas.cnpj &&
      configAsaas.password &&
      configAsaas.token_assas &&
      configAsaas.wallet &&
      configAsaas.utilizaFaturamento === 'S'
    ) {
      setError(false);
      setSuccess(false);
      setWarningFaturamento(false);

      setSendSave(true);
      
      const { status, messageKey, statusCode } = await receiveInCash({
        id: id,
        idAsaas: modalIDAsaas,
        paymentDate: modalDatePayment,
        value: modalValueReceived,
        token_assas: configAsaas.token_assas,
        observation: modalObservation,
      });
      setSendSave(false);

      if (status === 'SUCCESS') {
        setSuccess(true);
        setMessage(messageKey);

        getFinancials({ school: school_id, invalidInitialValue: true });
        closeModal();
      } else {
        if (statusCode === 400) {
          setShowModalCharge(true);
        } else {
          setError(true);
          setMessage(messageKey);
        }
      }

      scrollTop(modalScroll);
    } else {
      setError(true);
      setMessage('SystemMessage.Fail.SchoolMustBeParameterized');
      console.log('Erro ao comunicar com o servidor.');
      setWarningFaturamento(false);
      setSendSave(false);
    }
  };

  const handleSubmitClientFornecedor = async (formValues) => {
    try {
      if (formValues.cpf_cnpj.length === 14 && !validateCNPJ(formValues.cpf_cnpj, i18n.language)) {
        throw new Error('SystemMessage.Fail.InvalidCNPJ');
      } else if (formValues.cpf_cnpj.length === 11 && !validateCPF(formValues.cpf_cnpj, i18n.language)) {
        throw new Error('SystemMessage.Fail.InvalidCPF');
      } else if (formValues.streetName.length > 50) {
        throw new Error('SystemMessage.Fail.LongAdress');
      } else {
        let endpoint = '';
        let body = {
          school_id,
          name: formValues.name,
          cpf_cnpj: formValues.cpf_cnpj,
          phone: formValues.phone,
          email: formValues.email,
          type: formValues.type,

          zipCode: formValues.zipCode,
          streetName: formValues.streetName,
          streetNumber: formValues.streetNumber,
          complement: formValues.complement,
          neighborhood: formValues.neighborhood,
          city_id: formValues.cityId,
        };

        if (formValues.id === null) {
          endpoint = 'save-client-fornecedor-school';
          body = {
            ...body,
            user_id,
          };
        } else {
          endpoint = 'update-client-fornecedor-school';
          body = {
            ...body,
            id: formValues.id,
          };
        }

        const { data } = await api.post(endpoint, body);
        setModalStudentsClientsFornecedors(data.studentsClientsFornecedors);

        return {
          success: true,
          message: data.messageKey,
          data: data.create,
        };
      }
    } catch (error) {
      return {
        success: false,
        message: error.message,
      };
    }
  };

  //WhatsApp
  const openSendWhatsApp = async (item) => {
    const { status, users } = await getUserPhoneNumbers({ ids: [item.student_id], type: 'STUDENT' });

    if (status === 'SUCCESS') {
      setDetail(item);
      setUser(users[0]);
      whatsAppComponentRef.current.showModal();
    } else {
      whatsAppComponentRef.current.closeModal();
    }
  };

  const sendMessageWhatsApp = async (finance, user) => {
    var linkPdf = getReceiptUrl(finance);
    var message = `*RECIBO - PDF*\nOlá, ${user.name}. Segue seu recibo.`;

    const response = await sendWhatsAppMessageWithFileAlt({
      numbersList: [i18n.resolvedLanguage === 'pt-BR' ? `55${user.phone}` : user.phone],
      mimeType: 'pdf',
      message: message,
      fileLink: linkPdf,
    });

    if (response.success) {
      return { success: true, message: t('WhatsAppComponent.SendSuccessMessage.ReceiptSendSuccessfully') };
    } else {
      return {
        success: false,
        message: (
          <div>
            <b>{t('WhatsAppComponent.SendFailMessage.Fail')}</b>
            <br />
            <b>{user.name}:</b> {t('WhatsAppComponent.SendFailMessage.IncorrectNumber')}
            <br />
          </div>
        ),
      };
    }
  };

  const handleDocument = async (document) => {
    setProcess(true);
    const res = await attachDocument(id, document);
    setProcess(false);

    if (!res.success) {
      if (res.erro) {
        // let prop = '';
        // let msgs = '';
        // for (prop in res.erro) {
        //   if (res.erro.hasOwnProperty(prop)) {
        //     msgs += res.erro[prop][0];
        //   }
        // }

        const msgs = getErrorMessageFromFileResponse(res.erro);
        setError(true);
        setMessage(msgs);
      } else {
        setError(true);
        setMessage(res.messageKey);
      }
    } else {
      setSuccess(true);
      setMessage('SystemMessage.File.Success.DocumentAttachedSuccess');
      setModalDocument(dirDoc + '/' + res.documento.arquivo);
    }
  };

  useEffect(() => {
    if (modalPayment === 'Dinheiro') {
      setModalModo('A vista');
    }
  }, [modalPayment]);

  useEffect(() => {
    setModalAmount(modalModo === 'A vista' ? 1 : '');
  }, [modalModo]);

  const discoverCase = (financial) => discoverBillCase(financial, setFinanceType, 'SCHOOL');

  /**
   *
   * @param {{ unsaved: boolean, unbilled: boolean, contractAttached: boolean, contractDetached: boolean, financeAttached: boolean, financeDetached: boolean }?} cases
   * @returns {boolean}
   */
  const onCase = (cases) => onBillCase(cases, financeType);

  const handlePaidCharge = async () => {
    try {
      const response = await axios.post(baseURL + 'update-financial-school', {
        id,
        datePayment: modalDatePayment,
        valueReceived: modalValueReceived,
        payment_clientPayment: modalDatePayment,
        payment_status: 'Aprovado',
        received: 'S',
        dueDate: modalDueDate,
      });

      setShowModalCharge(false);

      if (response.status === 200) {
        setSuccess(true);
        setMessage('Baixa Realizada com sucesso!');

        getFinancials({ school: school_id, invalidInitialValue: true });
        closeModal();
      } else {
        setError(true);
        setMessage(response?.data?.message);
      }
    } catch (error) {
      setError(true);
      setMessage('SystemMessage.Fail.SchoolMustBeParameterized');
      console.log('Erro ao comunicar com o servidor.');
      setWarningFaturamento(false);
      setSendSave(false);
    }
  };

  const handlerSetDefaultPlan = (id) => {
    const financial = standPlans.find((plan) => plan.id == id);

    if (financial) {
      setModalType(financial.type);
      setModalCountDescription(financial.account_description);
      setModalPayment(financial.payment);
      setModalModo(financial.mode);
      setModalDueDate(financial.due_date);
      setModalValue(financial.value);
      setModalDiscountType(financial.discount_type === null ? 'NONE' : financial.discount_type);
      setModalDiscount(financial.discount);
      setModalLateInterestType(financial.late_interest_type === null ? 'NONE' : financial.late_interest_type);
      setModalLateInterest(financial.late_interest);
      setModalLateFeeType(financial.late_fee_type === null ? 'NONE' : financial.late_fee_type);
      setModalLateFee(financial.late_fee);
      setModalValueReceived(
        financial.valueReceived !== null && financial.valueReceived !== undefined ? +financial.valueReceived : null
      );
      setModalAmount(financial.amount);
      setModalReceived('N');
      setModalDatePayment('');
      setModalObservation(financial.observation);
    } else {
      newCount();
    }
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div style={{ paddingRight: '6rem', paddingLeft: '2rem' }}>
          <div className='row content_painel'>
            <main className='content_panel'>
              <div className='sidebar'>
                <div className='card' style={{ flexDirection: 'column' }}>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('SchoolFinancePage.ActionCard.Title')}</h2>
                    <p className='card_subtitle'>{t('SchoolFinancePage.ActionCard.Subtitle')}</p>
                  </div>
                  <div className='card-actions'>
                    <div className='card-action-row'>
                      <button onClick={newCount} className='btn_card col-10 mx-auto my-2' data-modal='#noticia'>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {t('SchoolFinancePage.ActionCard.Button')}
                      </button>
                    </div>
                    <div className='card-action-row'>
                      <Link to='/finacial/create-invoice-revenue' className='btn_card col-10 mx-auto my-2'>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        Criar Cobranças Multiplas
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('SchoolFinancePage.FilterCard.Title')}</h2>
                    <p className='card_subtitle'>{t('SchoolFinancePage.FilterCard.Subtitle')}</p>
                  </div>
                  <form action='' method='post' className='form_card'>
                    <div className='form_group'>
                      <label className='card_details_label'>{t('CustomerOrSupplier')}</label>
                      <Autocomplete
                        id='option-student-search'
                        style={{ width: '100%', zIndex: 0 }}
                        autoSelect
                        clearOnEscape
                        noOptionsText={t('NoCustomerOrSupplier')}
                        loadingText={t('SystemMessage.Loading.Loading')}
                        options={modalStudentsClientsFornecedors}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.idUser === value.idUser}
                        onChange={(event, newValue) => {
                          setFilterStudentClientFornecedor(newValue === null ? '' : newValue.name);
                        }}
                        renderInput={(params) => <TextField {...params} label={t('EnterTheName')} variant='outlined' />}
                      />
                    </div>
                    <div className='form_group'>
                      <label className='card_details_label'>{t('Classes_other')}</label>
                      <select
                        className='form_control select'
                        value={filterStudentClass}
                        onChange={(e) => setFilterStudentClass(e.target.value)}
                      >
                        <option value=''>{t('All', { context: 'female' })}</option>
                        {classes
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((studentClass, i) => (
                            <option key={'filter-select-key' + i} value={studentClass.id}>
                              {studentClass.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className='form_group'>
                      <label className='card_details_label'>{t('StudentBirthYear')}</label>
                      <input
                        type='number'
                        className='form_control'
                        value={filterStudentBirthYear}
                        onChange={(e) => setFilterStudentBirthYear(e.target.value)}
                      />
                    </div>
                    <div className='form_group'>
                      <label className='card_details_label'>{t('Types')}</label>
                      <select
                        className='form_control select'
                        value={filterType}
                        onChange={(e) => setFilterType(e.target.value)}
                      >
                        <option value=''>{t('All')}</option>
                        <option value='Receita'>{t('Revenue')}</option>
                        <option value='Despesa'>{t('Expense')}</option>
                      </select>
                    </div>
                    <div className='form_group'>
                      <label className='card_details_label'>{t('PaymentMethods')}</label>
                      <select
                        className='form_control select'
                        value={filterPayment}
                        onChange={(e) => setFilterPayment(e.target.value)}
                      >
                        <option value=''>{t('All')}</option>
                        <option value='Dinheiro'>{t('Money')}</option>
                        <option value='Cartão'>{t('Card')}</option>
                        <option value='Boleto'>{t('Bill')}</option>
                      </select>
                    </div>
                    <div className='form_group'>
                      <label className='card_details_label'>Recebido/Pago</label>
                      <select
                        className='form_control select'
                        value={filterStatus}
                        onChange={(e) => setFilterStatus(e.target.value)}
                      >
                        <option value=''>{t('All')}</option>
                        <option value='N'>{t('Not')}</option>
                        <option value='S'>{t('Yes')}</option>
                      </select>
                    </div>
                    <div className='form_group'>
                      <label className='card_details_label'>{t('FilterByDate')}</label>
                      <select
                        className='form_control select'
                        value={filterDateType}
                        onChange={(e) => setFilterDateType(e.target.value)}
                      >
                        <option value='OVERDUE'>{t('DueDate')}</option>
                        <option value='PAYDAY'>{t('PayDay')}</option>
                      </select>
                    </div>
                    {filterDateType === 'OVERDUE' && (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div className='group_50' style={{ width: '-webkit-calc(52% - 15px)' }}>
                          <div className='form_group'>
                            <label className='card_details_label'>{t('InitialOverdue')}</label>
                            <input
                              type='date'
                              className='form_control'
                              value={filterDueDateIni}
                              onChange={(e) => setFilterDueDateIni(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className='group_50' style={{ width: '-webkit-calc(52% - 15px)' }}>
                          <div className='form_group'>
                            <label className='card_details_label'>{t('EndOverdue')}</label>
                            <input
                              type='date'
                              className='form_control'
                              value={filterDueDateEnd}
                              onChange={(e) => setFilterDueDateEnd(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {filterDateType === 'PAYDAY' && (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div className='group_50' style={{ width: '-webkit-calc(52% - 15px)' }}>
                          <div className='form_group'>
                            <label className='card_details_label'>{t('InitialPaymentDate')}</label>
                            <input
                              type='date'
                              className='form_control'
                              value={filterDueDateIni}
                              onChange={(e) => setFilterDueDateIni(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className='group_50' style={{ width: '-webkit-calc(52% - 15px)' }}>
                          <div className='form_group'>
                            <label className='card_details_label'>{t('EndPaymentDate')}</label>
                            <input
                              type='date'
                              className='form_control'
                              value={filterDueDateEnd}
                              onChange={(e) => setFilterDueDateEnd(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {process && (
                      <button className='btn_card mx-auto' style={{ background: '#fdcc06', color: '#1c842c' }}>
                        <Loading type='1' />
                      </button>
                    )}
                    {!process && (
                      <button className='btn_card mx-auto' onClick={search}>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {t('Search')}
                      </button>
                    )}
                  </form>
                </div>
                <div className='card'>
                  <div className='card_header mx-auto' style={{ alignItems: 'center' }}>
                    <h2 className='card_title'>{t('BillsToReceive')}</h2>
                    {process ? (
                      <p className='loading-card sidebar_data' style={{ height: '95px' }}>
                        <Loading type='1' />
                      </p>
                    ) : (
                      <div className='sidebar_data'>
                        {valueR == 0
                          ? t('PlaceholderMoneyZero')
                          : t('FormatFunction.intlCurrencyWithOptions', { value: valueR })}
                      </div>
                    )}
                  </div>
                  <hr />
                  <div className='card_header mx-auto' style={{ alignItems: 'center' }}>
                    <h2 className='card_title'>{t('BillsToPay')}</h2>
                    {process ? (
                      <p className='loading-card sidebar_data' style={{ height: '95px' }}>
                        <Loading type='1' />
                      </p>
                    ) : (
                      <div className='sidebar_data'>
                        {valueD == 0
                          ? t('PlaceholderMoneyZero')
                          : t('FormatFunction.intlCurrencyWithOptions', { value: valueD })}
                      </div>
                    )}
                  </div>
                  <hr />
                  <div className='card_header mx-auto' style={{ alignItems: 'center' }}>
                    <h2 className='card_title'>{t('PeriodBalance')}</h2>
                    {process ? (
                      <p className='loading-card sidebar_data' style={{ height: '95px' }}>
                        <Loading type='1' />
                      </p>
                    ) : (
                      <div className='sidebar_data'>
                        {valueR - valueD == 0
                          ? t('PlaceholderMoneyZero')
                          : t('FormatFunction.intlCurrencyWithOptions', { value: valueR - valueD })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('SchoolFinancePage.PageTitle')}</h2>
                    <p className='card_subtitle'>{t('SchoolFinancePage.PageSubtitle')}</p>
                  </div>
                  <div className='list_card'>
                    <input
                      placeholder={currentTab === 'receitas' ? t('SearchCustomer') : t('SearchSupplier')}
                      style={{
                        width: '100%',
                        padding: '15px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        color: '#aaa',
                      }}
                      value={filterName}
                      onChange={(e) => setFilterName(e.target.value)}
                    />
                    {process && (
                      <p className='loading-card' style={{ marginTop: '5px' }}>
                        <Loading type='1' />
                      </p>
                    )}
                    {
                      <Tabs>
                        <TabList>
                          <Tab style={{ fontSize: 12.5 }} onClick={() => setCurrentTab('receitas')}>
                            {t('Revenues')}{' '}
                            <Badge pill variant='success' className='badge-font-size-90'>
                              {countR}
                            </Badge>
                          </Tab>
                          <Tab style={{ fontSize: 12.5 }} onClick={() => setCurrentTab('despesas')}>
                            {t('Expenses')}{' '}
                            <Badge pill variant='danger' className='badge-font-size-90'>
                              {countD}
                            </Badge>
                          </Tab>
                        </TabList>

                        <TabPanel>
                          <div style={{ overflowX: 'auto' }}>
                            <FinancialTable
                              type='INCOME'
                              isLoading={process}
                              onEdit={edit}
                              onOpenReceipt={showConfirmReceiptType}
                              onOpenSendWhatsApp={openSendWhatsApp}
                              sortIconState={{ get: sortIcon, set: setSortIcon }}
                              finances={filteredFinancials.filter((finance) => finance.type === 'Receita')}
                            />
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <div style={{ overflowX: 'auto' }}>
                            <FinancialTable
                              type='EXPENSE'
                              isLoading={process}
                              onEdit={edit}
                              sortIconState={{ get: sortIcon, set: setSortIcon }}
                              finances={filteredFinancials.filter((finance) => finance.type === 'Despesa')}
                            />
                          </div>
                        </TabPanel>
                      </Tabs>
                    }
                  </div>
                </div>
              </div>
              <div className='modal' style={display}>
                <div className='modal_content' ref={modalScroll}>
                  <div className='modal_close' onClick={closeModal}>
                    X
                  </div>
                  <h3 style={{ alignItems: 'flex-end', display: 'flex' }} className='modal_title'>
                    {id === null ? t('SchoolFinancePage.Modal.TitleAdd') : t('SchoolFinancePage.Modal.TitleEdit')}
                    {modalQuota !== '' && (
                      <Badge
                        pill
                        variant='secondary'
                        style={{
                          fontWeight: 500,
                          textTransform: 'capitalize',
                          paddingTop: '10px',
                          paddingBottom: '10px',
                          fontSize: '0% !important',
                          fontFamily: 'sans-serif',
                          marginLeft: '10px',
                        }}
                      >
                        {modalQuota}
                      </Badge>
                    )}
                  </h3>
                  <form
                    action=''
                    autoComplete='new-password'
                    onSubmit={handleValid}
                    method='post'
                    className='form_modal'
                  >
                    <div className='group_50'>
                      <div className='form_group'>
                        <label
                          className='card_details_label'
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          {t('Customer')}
                          <span
                            className='lowercase'
                            style={{
                              marginLeft: 10,
                              color: '#841202',
                              cursor: 'pointer',
                            }}
                            onClick={() => newClientFornecedor()}
                          >
                            {t('CreateOrUpdateCustomer')}
                          </span>
                        </label>
                        <Autocomplete
                          required
                          clearOnEscape={true}
                          disabled={onCase()}
                          id={'_' + Math.random() * 1000 + '_'}
                          noOptionsText={t('NoCustomerOrSupplier')}
                          loadingText={t('SystemMessage.Loading.Loading')}
                          options={modalStudentsClientsFornecedors}
                          getOptionLabel={(option) => (typeof option === 'string' ? '' : option.name)}
                          getOptionSelected={(option, value) => option.idUser === value.idUser}
                          style={{ width: '100%' }}
                          value={modalStudentClientFornecedor === '' ? null : modalStudentClientFornecedor}
                          onChange={(event, newValue) => {
                            setModalStudentClientFornecedor(newValue === null ? '' : newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off',
                                required: true,
                              }}
                              label={t('EnterTheName')}
                              variant='outlined'
                            />
                          )}
                        />
                      </div>
                    </div>
                    {/*   <div className='form_group w-100'>
                      <label className='card_details_label'>Usar um Plano Padrão</label>
                      <select className={`form_control select`} onChange={(e) => handlerSetDefaultPlan(e.target.value)}>
                        <option value=''>Selecionar Plano Padrão</option>
                        {standPlans.map((item, i) => (
                          <option key={`stdplan${i}`} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div> */}
                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>{t('Type')}</label>
                        <select
                          required
                          value={modalType}
                          disabled={onCase()}
                          className='form_control select'
                          onChange={(e) => setModalType(e.target.value)}
                        >
                          <option value='' disabled></option>
                          <option value='Receita'>{t('Revenue')}</option>
                          <option value='Despesa'>{t('Expense')}</option>
                        </select>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>{t('PostingAccount')}</label>
                        <input
                          type='text'
                          required
                          className='form_control'
                          value={modalCountDescription}
                          onChange={(e) => setModalCountDescription(e.target.value)}
                        />
                        {/* <select
                          required
                          disabled={onCase()}
                          className={`form_control select`}
                          value={modalCountDescription}
                          onChange={(e) => setModalCountDescription(e.target.value)}
                        >
                          <option value='' disabled></option>
                          {financialAccountOptions.map((option) => (
                            <option key={`School_Financial_CountDescription_${option}`} value={option}>
                              {option}
                            </option>
                          ))}
                          {modalInitialCountDescription !== null && (
                            <option value={modalInitialCountDescription} disabled>
                              {modalInitialCountDescription}
                            </option>
                          )}
                        </select> */}
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>{t('PaymentMethod')}</label>
                        <select
                          required
                          disabled={onCase()}
                          className={`form_control select`}
                          value={modalPayment}
                          onChange={(e) => setModalPayment(e.target.value)}
                        >
                          <option value='' disabled></option>
                          <option value='Dinheiro'>{t('Money')}</option>
                          <option value='Cartão'>{t('Card')}</option>
                          <option value='Boleto'>{t('Bill')}</option>
                          <option
                            value='Cartão_INTEGRATED'
                            disabled={!(!!configAsaas.token_assas && modalType === 'Receita')}
                          >
                            {t('Card')} ({t('Integrated')})
                          </option>
                          <option
                            value='Boleto_INTEGRATED'
                            disabled={!(!!configAsaas.token_assas && modalType === 'Receita')}
                          >
                            {t('Bill')}/PIX ({t('Integrated')})
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>{t('Mode')}</label>
                        <select
                          required
                          disabled={onCase()}
                          className={`form_control select`}
                          value={modalModo}
                          onChange={(e) => setModalModo(e.target.value)}
                        >
                          <option value='' disabled></option>
                          <option value='A vista'>{t('InCash')}</option>
                          {(configAsaas.token_assas || modalType == 'Despesa') && modalPayment != 'Dinheiro' && (
                            <option value='Parcelado'>{t('PaymentInstallments')}</option>
                          )}

                          {!configAsaas.token_assas && modalType == 'Receita' && modalPayment != 'Dinheiro' && (
                            <option value='Parcelado' disabled>
                              {t('PaymentInstallments')} ({t('IntegratedOnly')})
                            </option>
                          )}

                          {modalModo == 'Assinatura' && (
                            <option value='Assinatura' disabled>
                              {t('ContractSigning')} ({t('IntegratedOnly')})
                            </option>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>{t('ExpirationDate')}</label>
                        <input
                          type='date'
                          required
                          className='form_control'
                          disabled={onCase({
                            unsaved: false,
                            unbilled: false,
                            contractAttached: true,
                            contractDetached: true,
                            financeAttached: true,
                            financeDetached: false,
                          })}
                          value={modalDueDate}
                          onChange={(e) => setModalDueDate(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label'>{t('DiscountType')}</label>
                          <select
                            className={`form_control select`}
                            value={modalDiscountType}
                            onChange={(e) => {
                              setModalDiscountType(e.target.value);
                              setModalDiscount(null);
                            }}
                            disabled={onCase({
                              unsaved: false,
                              unbilled: false,
                              contractAttached: true,
                              contractDetached: true,
                              financeAttached: true,
                              financeDetached: false,
                            })}
                          >
                            <option value='NONE'>{t('NoDiscount')}</option>
                            <option value='PERCENTAGE'>{t('Percentage')}</option>
                            <option value='FIXED'>{t('Fixed')}</option>
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label'>{t('DiscountAmount')}</label>
                          <NumberFormat
                            required
                            className='form_control'
                            displayType={'input'}
                            placeholder={
                              modalDiscountType === 'FIXED'
                                ? t('PlaceholderMoneyZero')
                                : modalDiscountType === 'PERCENTAGE'
                                ? t('PlaceholderPercentageZero')
                                : ''
                            }
                            format={(e) =>
                              modalDiscountType === 'FIXED'
                                ? t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })
                                : modalDiscountType === 'PERCENTAGE'
                                ? t('FormatFunction.intlPercentWithOptions', { value: +e / 100 })
                                : ''
                            }
                            disabled={onCase({
                              unsaved: false,
                              unbilled: false,
                              contractAttached: true,
                              contractDetached: true,
                              financeAttached: true,
                              financeDetached: false,
                            })}
                            readOnly={modalDiscountType === 'NONE'}
                            isAllowed={({ floatValue }) =>
                              modalDiscountType === 'PERCENTAGE' ? floatValue < 10000 : true
                            }
                            value={modalDiscount * 100}
                            onValueChange={(e) => setModalDiscount(e.floatValue / 100 || undefined)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label'>{t('InstallmentValueWithoutDiscount')}</label>
                          <MoneyMask
                            required
                            value={modalValue}
                            getInputRef={modalValueRef}
                            onChange={setModalValue}
                            disabled={onCase({
                              unsaved: false,
                              unbilled: false,
                              contractAttached: true,
                              contractDetached: true,
                              financeAttached: true,
                              financeDetached: false,
                            })}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label'>{t('NumberOfInstallments')}</label>
                          <select
                            required
                            disabled={onCase()}
                            className={`form_control select`}
                            value={modalAmount}
                            onChange={(e) => setModalAmount(e.target.value)}
                          >
                            <option value='' disabled></option>
                            {modalModo !== 'Parcelado' && <option value='1'>01 ({t('InCash')})</option>}
                            <option value='2'>02</option>
                            <option value='3'>03</option>
                            <option value='4'>04</option>
                            <option value='5'>05</option>
                            <option value='6'>06</option>
                            <option value='7'>07</option>
                            <option value='8'>08</option>
                            <option value='9'>09</option>
                            <option value='10'>10</option>
                            <option value='11'>11</option>
                            <option value='12'>12</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label'>{t('LateInterestType')}</label>
                          <select
                            className={`form_control select`}
                            value={modalLateInterestType}
                            onChange={(e) => {
                              setModalLateInterestType(e.target.value);
                              setModalLateInterest(null);
                            }}
                            disabled={onCase({
                              unsaved: false,
                              unbilled: false,
                              contractAttached: true,
                              contractDetached: true,
                              financeAttached: true,
                              financeDetached: false,
                            })}
                          >
                            <option value='NONE'>{t('NoLateInterest')}</option>
                            <option value='PERCENTAGE'>{t('Percentage')}</option>
                            <option value='FIXED'>{t('Fixed')}</option>
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label'>{t('LateInterestAmount')}</label>
                          <NumberFormat
                            className='form_control'
                            displayType={'input'}
                            placeholder={
                              modalLateInterestType === 'FIXED'
                                ? t('PlaceholderMoneyZero')
                                : modalLateInterestType === 'PERCENTAGE'
                                ? t('PlaceholderPercentageZero')
                                : ''
                            }
                            format={(e) =>
                              modalLateInterestType === 'FIXED'
                                ? t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })
                                : modalLateInterestType === 'PERCENTAGE'
                                ? t('FormatFunction.intlPercentWithOptions', { value: +e / 100 })
                                : ''
                            }
                            disabled={onCase({
                              unsaved: false,
                              unbilled: false,
                              contractAttached: true,
                              contractDetached: true,
                              financeAttached: true,
                              financeDetached: false,
                            })}
                            readOnly={modalLateInterestType === 'NONE'}
                            isAllowed={({ floatValue }) =>
                              modalLateInterestType === 'PERCENTAGE' ? floatValue < 10000 : true
                            }
                            value={modalLateInterest * 100 || null}
                            onValueChange={(e) => setModalLateInterest(e.floatValue / 100 || undefined)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label'>{t('LateFeeType')}</label>
                          <select
                            className={`form_control select`}
                            value={modalLateFeeType}
                            onChange={(e) => {
                              setModalLateFeeType(e.target.value);
                              setModalLateFee(null);
                            }}
                            disabled={onCase({
                              unsaved: false,
                              unbilled: false,
                              contractAttached: true,
                              contractDetached: true,
                              financeAttached: true,
                              financeDetached: false,
                            })}
                          >
                            <option value='NONE'>{t('NoLateFee')}</option>
                            <option value='PERCENTAGE'>{t('Percentage')}</option>
                            <option value='FIXED'>{t('Fixed')}</option>
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label'>{t('LateFeeAmount')}</label>
                          <NumberFormat
                            className='form_control'
                            displayType={'input'}
                            placeholder={
                              modalLateFeeType === 'FIXED'
                                ? t('PlaceholderMoneyZero')
                                : modalLateFeeType === 'PERCENTAGE'
                                ? t('PlaceholderPercentageZero')
                                : ''
                            }
                            format={(e) =>
                              modalLateFeeType === 'FIXED'
                                ? t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })
                                : modalLateFeeType === 'PERCENTAGE'
                                ? t('FormatFunction.intlPercentWithOptions', { value: +e / 100 })
                                : ''
                            }
                            disabled={onCase({
                              unsaved: false,
                              unbilled: false,
                              contractAttached: true,
                              contractDetached: true,
                              financeAttached: true,
                              financeDetached: false,
                            })}
                            readOnly={modalLateFeeType === 'NONE'}
                            isAllowed={({ floatValue }) =>
                              modalLateFeeType === 'PERCENTAGE' ? floatValue < 10000 : true
                            }
                            value={modalLateFee * 100}
                            onValueChange={(e) => setModalLateFee(e.floatValue / 100 || undefined)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                        <label className='card_details_label'>Recebido/Pago</label>
                        <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              height: '100%',
                            }}
                          >
                            <div
                              className='group_50'
                              style={{
                                alignItems: 'center',
                                justifyContent: 'start',
                              }}
                            >
                              <input
                                type='radio'
                                checked={modalReceived === 'S' ? 'checked' : ''}
                                disabled={onCase({
                                  unsaved: false,
                                  unbilled: false,
                                  contractAttached: true,
                                  contractDetached: false,
                                  financeAttached: true,
                                  financeDetached: false,
                                })}
                                name='received'
                                className='form_control'
                                style={{ width: '15px', cursor: 'pointer', height: '15px' }}
                                value='S'
                                onChange={(e) => {
                                  const today = moment().format('YYYY-MM-DD');
                                  setModalReceived(e.target.value);
                                  setModalValueReceived(
                                    calcFinanceValue(
                                      modalValue,
                                      true,
                                      today,
                                      modalDueDate,
                                      { type: modalDiscountType, value: modalDiscount },
                                      { type: modalLateInterestType, value: modalLateInterest },
                                      { type: modalLateFeeType, value: modalLateFee }
                                    )
                                  );
                                  setModalDatePayment(today);
                                }}
                              />{' '}
                              <span className='lowercase' style={{ margin: '0 10px' }}>
                                {t('Yes')}
                              </span>
                            </div>
                            <div
                              className='group_50'
                              style={{
                                alignItems: 'center',
                                justifyContent: 'start',
                              }}
                            >
                              <input
                                type='radio'
                                checked={modalReceived === 'N' ? 'checked' : ''}
                                disabled={onCase({
                                  unsaved: false,
                                  unbilled: false,
                                  contractAttached: true,
                                  contractDetached: false,
                                  financeAttached: true,
                                  financeDetached: false,
                                })}
                                name='received'
                                className='form_control'
                                style={{ width: '15px', cursor: 'pointer', height: '15px' }}
                                value='N'
                                onChange={(e) => {
                                  setModalReceived(e.target.value);
                                  setModalValueReceived(null);
                                  setModalDatePayment(null);
                                }}
                              />{' '}
                              <span className='lowercase' style={{ margin: '0 10px' }}>
                                {t('Not')}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                        <label className='card_details_label'>Valor recebido/pago</label>
                          <NumberFormat
                            className='form_control'
                            displayType={'input'}
                            format={(e) => t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })}
                            disabled={
                              (onCase({
                                unsaved: false,
                                unbilled: false,
                                contractAttached: true,
                                contractDetached: false,
                                financeAttached: true,
                                financeDetached: false,
                              }) ||
                                modalReceived === 'N') &&
                              !isAllowedReceiveInCash
                            }
                            readOnly={modalReceived == 'N'}
                            value={typeof modalValueReceived === 'number' ? modalValueReceived * 100 : ''}
                            onValueChange={(e) =>
                              setModalValueReceived(typeof e.floatValue === 'number' ? e.floatValue / 100 : null)
                            }
                            required={
                              (modalPayment === 'Dinheiro' && modalReceived === 'S') || isAllowedReceiveInCash
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label className='card_details_label'>{t('PayDay')}</label>
                        <input
                          type='date'
                          className='form_control'
                          disabled={
                            (onCase({
                              unsaved: false,
                              unbilled: false,
                              contractAttached: true,
                              contractDetached: false,
                              financeAttached: true,
                              financeDetached: false,
                            }) ||
                              modalReceived === 'N') &&
                            !isAllowedReceiveInCash
                          }
                          value={modalDatePayment || ''}
                          onChange={(e) => setModalDatePayment(e.target.value)}
                          required={
                            (modalPayment === 'Dinheiro' && modalReceived === 'S') || isAllowedReceiveInCash
                              ? true
                              : false
                          }
                        />
                      </div>
                    </div>
                    {id !== null && (
                      <React.Fragment>
                        {process ? (
                          <p style={{ textAlign: 'center' }}>
                            {t('SystemMessage.Loading.Wait')} <Loading type='4' style={{ color: 'green' }} />
                          </p>
                        ) : (
                          <div className='group_50' style={{ width: '65%' }}>
                            <div className='form_group'>
                              <label htmlFor='anexo' className='card_details_label'>
                                {t('Attachment')}
                              </label>
                              <input
                                type='file'
                                name='files[]'
                                id='anexo'
                                style={{
                                  display: 'block',
                                  padding: '13px',
                                  color: modalDocument !== '' ? 'white' : '#757575',
                                }}
                                className='form_control'
                                onChange={(e) => handleDocument(e.target.files[0])}
                              />
                            </div>
                          </div>
                        )}

                        {modalDocument === '' && (
                          <div className='group_50' style={{ width: '35%' }}>
                            <div className='form_group'>
                              <label className='card_details_label'>&nbsp;</label>
                              <input
                                style={{
                                  background: '#fff',
                                  textAlign: 'center',
                                  color: '#757575',
                                  borderRadius: '0 4px 4px',
                                  fontWeight: 'bold',
                                }}
                                disabled
                                className='form_control'
                                value={t('NoDocument')}
                              />
                            </div>
                          </div>
                        )}
                        {modalDocument !== '' && (
                          <div className='group_50' style={{ width: '35%' }}>
                            <div className='form_group'>
                              <label className='card_details_label'>&nbsp;</label>
                              <a
                                href={modalDocument}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{
                                  background: '#FFCB08',
                                  textAlign: 'center',
                                  color: '#1b7c2c',
                                  borderRadius: '0 4px 4px',
                                  fontWeight: 'bold',
                                  cursor: 'pointer',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                                className='form_control'
                              >
                                {t('ViewAttachedDocument')}
                              </a>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    )}

                    <div className='form_group'>
                      <label className='card_details_label'>{t('Observations')}</label>
                      <textarea
                        name='descript'
                        value={modalObservation || ''}
                        className='form_control'
                        id=''
                        cols='30'
                        rows='5'
                        onChange={(e) => setModalObservation(e.target.value)}
                      ></textarea>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      {onCase({
                        unsaved: true,
                        unbilled: false,
                        contractAttached: false,
                        contractDetached: false,
                        financeAttached: false,
                        financeDetached: false,
                      }) &&
                        !sendSave && (
                          <button className='btn_card' style={{ marginRight: 5 }}>
                            <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                              <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                            </svg>
                            {t('SchoolFinancePage.Modal.ButtonAdd')}
                          </button>
                        )}
                      {onCase({
                        unsaved: false,
                        unbilled: true,
                        contractAttached: true,
                        contractDetached: true,
                        financeAttached: true,
                        financeDetached: true,
                      }) &&
                        !isAllowedReceiveInCash &&
                        !sendSave && (
                          <button className='btn_card' style={{ marginRight: 5 }}>
                            <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                              <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                            </svg>
                            {t('SchoolFinancePage.Modal.ButtonEdit')}
                          </button>
                        )}
                      {onCase({
                        unsaved: false,
                        unbilled: false,
                        contractAttached: true,
                        contractDetached: false,
                        financeAttached: true,
                        financeDetached: false,
                      }) &&
                        !isAllowedReceiveInCash &&
                        modalReceived == 'N' &&
                        !sendSave && (
                          <div
                            className='btn_card btn_card_grey'
                            style={{ marginRight: 5 }}
                            onClick={allowReceiveInCash}
                          >
                            <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                              <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                            </svg>
                            {t('SchoolFinancePage.Modal.ReceiveInCash')}
                          </div>
                        )}
                      {onCase({
                        unsaved: false,
                        unbilled: false,
                        contractAttached: true,
                        contractDetached: false,
                        financeAttached: true,
                        financeDetached: false,
                      }) &&
                        isAllowedReceiveInCash &&
                        !sendSave && (
                          <button className='btn_card' style={{ marginRight: 5 }}>
                            <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                              <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                            </svg>
                            {t('Confirm')}
                          </button>
                        )}
                      {onCase({
                        unsaved: false,
                        unbilled: true,
                        contractAttached: false,
                        contractDetached: false,
                        financeAttached: false,
                        financeDetached: true,
                      }) &&
                        !sendSave && (
                          <div className='btn_card_red' onClick={() => handleDeleteCount()}>
                            <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                              <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                            </svg>
                            {t('SchoolFinancePage.Modal.ButtonDelete')}
                          </div>
                        )}
                      {onCase({
                        unsaved: false,
                        unbilled: false,
                        contractAttached: false,
                        contractDetached: false,
                        financeAttached: true,
                        financeDetached: false,
                      }) &&
                        !sendSave && (
                          <div className='btn_card_red' onClick={() => showMessageInfo()}>
                            <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                              <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                            </svg>
                            {t('SchoolFinancePage.Modal.ButtonDeleteCancelInvoice')}
                          </div>
                        )}

                      {paymentBankSlipUrl !== null && (
                        <ButtonRounded
                          isBig
                          variant='yellow'
                          type='button'
                          onClick={() => window.open(paymentBankSlipUrl, '_blank', 'noopener')}
                        >
                          <FaFileInvoiceDollar /> {t('SecondCopy_abbreviated')}
                        </ButtonRounded>
                      )}
                    </div>
                  </form>
                </div>
              </div>

              <ModalFinanceStakeholder
                isShow={displayClientFornecedor}
                handleCloseButton={() => closeModalClientFornecedor()}
                listClienteFornecedor={modalStudentsClientsFornecedors.filter((item) => ['C', 'F'].includes(item.type))}
                onSubmit={handleSubmitClientFornecedor}
              />

              <div className='modal' style={displayModalMessage} id='message'>
                <div className='modal_content' style={{ minHeight: 'auto', paddingBottom: 30, height: 'auto' }}>
                  <div
                    className='modal_close'
                    onClick={() => {
                      setDisplayModalMessage({});
                      setError(false);
                      setSuccess(false);
                      setDeleted(false);
                      setWarningFaturamento(false);
                      setInfoFaturamento(false);
                      setSendSave(false);
                    }}
                  >
                    X
                  </div>
                  <h3 className='modal_title'>{t('Alert')}</h3>
                  {error && (
                    <MessageDangerMarginTop title={t('Attention_exclamation') + ' '} description={t(message)} />
                  )}
                  {success && (
                    <MessageSuccessMarginTop title={t('Success_exclamation') + ' '} description={t(message)} />
                  )}
                  {deleted && (
                    <div
                      style={{
                        alignItems: 'center',
                        justifyContent: 'initial',
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          textAlign: 'center',
                          marginTop: 5,
                        }}
                      >
                        {t('SystemMessage.Loading.WaitDeleting')}
                        <Loading
                          type='1'
                          style={{
                            marginLeft: 5,
                            color: 'green',
                            borderWidth: '.1em',
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {warningFaturamento && (
                    <div className='alert alert-margin-top alert-warning alert-dismissible fade show' role='alert'>
                      <Trans i18nKey='SchoolFinancePage.SystemMessage.WarningInvoice'>
                        <strong>a</strong>
                        b
                        <br />
                        <br />c
                        <strong style={{ cursor: 'pointer' }} onClick={handleFaturar}>
                          d
                        </strong>
                      </Trans>
                    </div>
                  )}
                  {inforFaturamento && (
                    <div className='alert alert-margin-top alert-info alert-dismissible fade show' role='alert'>
                      <Trans i18nKey='SchoolFinancePage.SystemMessage.WarningCancelInvoice'>
                        <strong>a</strong>
                        b
                        <br />
                        <br />c
                        <strong style={{ cursor: 'pointer' }} onClick={handleDeleteCountFaturamento}>
                          d
                        </strong>
                      </Trans>
                    </div>
                  )}
                  {sendSave && (
                    <p style={{ textAlign: 'center' }}>
                      {t('SystemMessage.Loading.WaitProcessing')} <Loading type='4' style={{ color: 'green' }} />
                    </p>
                  )}
                </div>
              </div>

              <WhatsAppComponent
                ref={whatsAppComponentRef}
                sendMessageWhatsApp={() => sendMessageWhatsApp(detail, user)}
                onClose={() => {
                  setDetail(null);
                  setUser(null);
                }}
                sendButtonText={t('WhatsAppComponent.SendButtonText.SendReceipt')}
              >
                {detail && user && (
                  <>
                    <div className='img_list_user'>
                      <img src={user.studentPhotoPath} alt='' />
                    </div>
                    <h3 className='whatsapp-title-contrato'>
                      {t('Receipt')}: <br /> <b>{detail.student}</b>
                    </h3>
                  </>
                )}
              </WhatsAppComponent>

              <SweetAlert
                ref={alertRef}
                show={showModalCharge}
                warning
                title='Atenção!'
                confirmBtnText='Baixar'
                onConfirm={handlePaidCharge}
                showCancel={true}
                cancelBtnBsStyle='danger'
                cancelBtnText='Cancelar'
                onCancel={() => setShowModalCharge(false)}
              >
                <p>Está cobrança não se encontra pedente no banco ASAAS.</p>
                <br />
                <p>Vamos baixar somente no Fensor!</p>
              </SweetAlert>
            </main>

            <Footer />
          </div>
        </div>
      </div>
      {confirmReceiptType}
    </main>
  );
};

export default Financial;
