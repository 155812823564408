import React, { useEffect, useState } from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/club/Footer';
import Loading from '../../components/Loading';
import Utils from '../../utils/utils';
import Api from '../../utils/api';
import axios from 'axios';
import { capitalize, exportToXLS, formatPercentageOrFixed } from '../../../src/utils/utils';
import { dateFormatI18n } from '../../utils/formatters';

import { calcFinanceValue } from '../../utils/utils-financial';

const formatInsurance = (insurance) => {
  const insurances = {
    NONE: null,
    PLAN_A: 1.99,
    PLAN_B: 2.99,
    PLAN_C: 3.99,
  };

  return insurances[insurance];
};

const Reports = (props) => {
  const { t, i18n } = useTranslation();

  // indexs
  const [company_id, setCompanyID] = useState('');
  const [user_id, setUserID] = useState('');

  // carregamentos
  const [schools, setSchools] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [financials, setFinancials] = useState([]);
  const [students, setStudents] = useState([]);
  const [studentsContract, setStudentsContract] = useState([]);

  // loading
  const [process, setProcess] = useState(false);
  const [processStudent, setProcessStudent] = useState(false);
  const [processSchool, setProcessSchool] = useState(false);
  const [processContract, setProcessContract] = useState(false);
  const [processFinancial, setProcessFinancial] = useState(false);

  // outros
  const [totalStudents, setTotalStudents] = useState(0);
  const [totalContracts, setTotalContracts] = useState(0);
  const [totalFinancialsRC, setTotalFinancialsRC] = useState(0);
  const [totalFinancialsPG, setTotalFinancialsPG] = useState(0);
  const [totalSchools, setTotalSchools] = useState(0);
  //const [logoff, setLogoff] = useState(0)

  // libraries
  const baseURL = Api();
  const { currencyFormatterText, dateFormat } = Utils();

  useEffect(() => {
    async function getSession() {
      const usuSession = JSON.parse(await localStorage.getItem('user'));
      const companySession = JSON.parse(await localStorage.getItem('company'));

      setUserID(usuSession.id);
      setCompanyID(companySession.id);
      getReports(companySession.id);
    }

    getSession();
  }, []);

  const getReports = async (company_id) => {
    setProcess(true);
    const res = await axios.post(baseURL + 'get-reports-club', {
      company_id,
    });

    setStudents(res.data.students);
    setContracts(res.data.contracts);
    setFinancials(
      res.data.financials.map((fat) => ({
        ...fat,
        valueCalculated: Number(fat.valueReceived)
          ? +fat.valueReceived
          : calcFinanceValue(
              fat.value,
              fat.received === 'S',
              fat.datePayment,
              fat.dueDate,
              { type: fat.discount_type, value: fat.discount },
              { type: fat.late_interest_type, value: fat.late_interest },
              { type: fat.late_fee_type, value: fat.late_fee }
            ),
      }))
    );
    setSchools(res.data.schools);

    const flatten_contracts = res.data.students_contracts.reduce((acc, curr) => acc.concat(curr), []);

    console.log(flatten_contracts);

    setStudentsContract(flatten_contracts);

    setTotalStudents(res.data.total_students);
    setTotalContracts(res.data.total_contracts);
    setTotalFinancialsRC(res.data.total_financials_rc);
    setTotalFinancialsPG(res.data.total_financials_pg);
    setTotalSchools(res.data.total_schools);

    setProcess(false);
  };

  const exportToXLS = (type) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let csvData = [];
    let item = {};
    let fileName = '';

    switch (type) {
      case 'student':
        {
          setProcessStudent(true);
          fileName = 'Alunos';
          for (var i = 0; i < students.length; i = i + 1) {
            item = {
              nome: students[i].name,
              cpf: students[i].usuCPF,
              email: students[i].email,
              telefone: students[i].usuPhone,
              aniversario: dateFormat(students[i].usuBirth),
              status: students[i].status,
              responsavel: students[i].athNomeRespFin,
              'responsavel cpf': students[i].athCPFRespFin,
              'responsavel email': students[i].athEmailRespFin,
              'responsavel telefone': students[i].athPhoneRespFin,
              cep: students[i].usuCEP,
              endereco: students[i].usuEndereco,
              bairro: students[i].usuBairro,
              sobre: students[i].usuSobre,
              'criado em': students[i].usuCreatedAt,
              'criado por': students[i].userCreatedBy_name,
              'atualizado em': students[i].usuUpdatedAt,
              'atualizado por': students[i].userUpdatedBy_name,
              escola: students[i].schName,
              beneficio: students[i].benefitName,
            };
            csvData.push(item);
          }
        }
        break;
      case 'school':
        {
          setProcessSchool(true);
          fileName = 'Escolas';

          for (var i = 0; i < schools.length; i = i + 1) {
            item = {
              razao_social: schools[i].name,
              nome_fantasia: schools[i].schFantasia,
              cpf: schools[i].cpf_cnpj,
              email: schools[i].email,
              telefone: schools[i].schPhone,
              status: schools[i].schStatus,
              cep: schools[i].schCep,
              endereco: schools[i].schLogradouro,
              numero: schools[i].schNumero,
              bairro: schools[i].schBairro,
              cidade: schools[i].schLocalizacao,
              uf: schools[i].schEstado,
              responsavel: schools[i].schResponsavel,
              sobre: schools[i].usuSobre,
              'Quantidade de estudantes': schools[i].students,
            };
            csvData.push(item);
          }
        }
        break;
      case 'contract':
        {
          setProcessContract(true);
          fileName = 'Contratos';
          for (var i = 0; i < contracts.length; i = i + 1) {
            item = {
              escola: contracts[i].schName,
              cnpj: contracts[i].schCnpj,
              telefone: contracts[i].schPhone,
              email: contracts[i].schEmail,
              responsavel: contracts[i].schResponsavel,
              servico: contracts[i].service,
              valor: contracts[i].value,
              faturando: contracts[i].faturado,
              pagamento: contracts[i].payment == 'CREDIT_CARD' ? 'Cartão' : 'Boleto',
              dataInicio: dateFormat(contracts[i].dateStart),
              dataFim: dateFormat(contracts[i].dateEnd),
              status: contracts[i].status,
              numeroContrato: contracts[i].number,
              observacao: contracts[i].observation,
            };
            csvData.push(item);
          }
        }
        break;
      case 'financial':
        {
          setProcessFinancial(true);
          fileName = 'Financeiro';
          for (var i = 0; i < financials.length; i = i + 1) {
            item = {
              cliente:
                financials[i].type == 'Receita'
                  ? financials[i].school !== null
                    ? financials[i].school
                    : financials[i].client
                  : '',
              fornecedor:
                financials[i].type == 'Despesa'
                  ? financials[i].school !== null
                    ? financials[i].school
                    : financials[i].client
                  : '',
              tipo: financials[i].type,
              conta: financials[i].countDescription,
              vencimento: dateFormat(financials[i].dueDate),
              parcela: financials[i].quota,
              recebido: financials[i].received,
              modo: financials[i].mode,
              observacao: financials[i].observation,
              responsavel: financials[i].responsavel,
              ['valor do contrato']: +financials[i].value,
              valor: +financials[i].valueCalculated,
              status: financials[i].payment_status,
              pagamento: financials[i].payment,
              ['id transação']: financials[i].payment_id_asaas || '',
            };
            csvData.push(item);
          }
        }
        break;
      case 'contract_students': {
        fileName = t('Contracts');
        for (const contract of studentsContract) {
          csvData.push({
            [t('School')]: contract.schName,
            CNPJ: contract.schCnpj,
            [t('Student')]: contract.nameStudent,
            [t('CPF')]: contract.usuCPF,
            [t('Email')]: contract.email,
            [t('Responsible')]: contract.athNomeRespFin,
            'Telefone Responsável': contract.athPhoneRespFin,
            'Email Responsável': contract.athEmailRespFin,
            [t('Service')]: contract.service,
            [t('Workload')]: contract.cargaHoraria,
            [t('BillingCycle')]: t(capitalize(contract.cyclo)),
            [t('Value')]: +contract.value,
            [t('ContractedInsurance')]: formatInsurance(contract.insurance),
            [t('DiscountType')]: t(capitalize(contract.discount_type || '')),
            [t('DiscountAmount')]: formatPercentageOrFixed(contract.discount_type, contract.discount),
            [t('LateInterestType')]: t(capitalize(contract.late_interest_type || '')),
            [t('LateInterestAmount')]: formatPercentageOrFixed(contract.late_interest_type, contract.late_interest),
            [t('LateFeeType')]: t(capitalize(contract.late_fee_type || '')),
            [t('LateFeeAmount')]: formatPercentageOrFixed(contract.late_fee_type, contract.late_fee),
            [t('Billing')]: contract.faturado,
            [t('Payment')]:
              contract.payment == 'CREDIT_CARD' ? 'Cartão' : contract.payment == 'MONEY' ? 'Dinheiro' : 'Boleto',
            [t('ContractStartDate')]: contract.contractDateStart
              ? dateFormatI18n(contract.contractDateStart, i18n.language)
              : '',
            [t('FirstDueDate')]: dateFormatI18n(contract.dateStart, i18n.language),
            [t('ContractEndDate')]: contract.dateEnd ? dateFormatI18n(contract.dateEnd, i18n.language) : '',
            [t('Status')]: contract.status,
            [t('ContractNumber')]: contract.number,
            [t('CreatedAt')]: dateFormatI18n(contract.created_at, i18n.language),
           /*  [t('CreatedBy')]: contract.user_id_name, */
            [t('Observations')]: contract.observation,
          });
        }
      }
    }

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);

    setTimeout(() => {
      setProcessStudent(false);
      setProcessSchool(false);
      setProcessContract(false);
      setProcessFinancial(false);
    }, 3000);
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            {/*   */}

            <main className='content_panel'>
              <div className='sidebar'>
                <div className='card'>
                  <div className='card_header mx-auto' style={{ alignItems: 'center' }}>
                    <h2 className='card_title'>Escolas</h2>
                    {process ? (
                      <p className='loading-card sidebar_data' style={{ height: '95px' }}>
                        <Loading type='1' />
                      </p>
                    ) : (
                      <div className='sidebar_data'>{totalSchools}</div>
                    )}
                  </div>
                  <hr />
                  <div className='card_header mx-auto' style={{ alignItems: 'center' }}>
                    <h2 className='card_title'>Alunos</h2>
                    {process ? (
                      <p className='loading-card sidebar_data' style={{ height: '95px' }}>
                        <Loading type='1' />
                      </p>
                    ) : (
                      <div className='sidebar_data'>{totalStudents}</div>
                    )}
                  </div>
                  <hr />
                  <div className='card_header mx-auto' style={{ alignItems: 'center' }}>
                    <h2 className='card_title'>Contratos</h2>
                    {process ? (
                      <p className='loading-card sidebar_data' style={{ height: '95px' }}>
                        <Loading type='1' />
                      </p>
                    ) : (
                      <div className='sidebar_data'>{totalContracts}</div>
                    )}
                  </div>
                  <hr />
                  <div className='card_header mx-auto' style={{ alignItems: 'center' }}>
                    <h2 className='card_title'>Receita</h2>
                    {process ? (
                      <p className='loading-card sidebar_data' style={{ height: '95px' }}>
                        <Loading type='1' />
                      </p>
                    ) : (
                      <div className='sidebar_data'>
                        {totalFinancialsRC == 0 ? 'R$ 0,00' : currencyFormatterText(totalFinancialsRC)}
                      </div>
                    )}
                  </div>
                  <hr />
                  <div className='card_header mx-auto' style={{ alignItems: 'center' }}>
                    <h2 className='card_title'>Despesa</h2>
                    {process ? (
                      <p className='loading-card sidebar_data' style={{ height: '95px' }}>
                        <Loading type='1' />
                      </p>
                    ) : (
                      <div className='sidebar_data'>
                        {totalFinancialsPG == 0 ? 'R$ 0,00' : currencyFormatterText(totalFinancialsPG)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>Relatórios</h2>
                    <p className='card_subtitle'>Veja abaixo os relatórios disponíveis.</p>
                  </div>
                  <div className='list_card'>
                    <div className='card_list_turma'>
                      <div className='content_card'>
                        <div className='card_details'>
                          <p className='card_details_label text_uppercase'>Relatório das Escolas </p>
                          <div className='btn_group_card'>
                            <button
                              style={{ background: 'white', border: 'none' }}
                              onClick={(e) => exportToXLS('school')}
                              className='btn_link_card'
                              download='nome do arquivo'
                            >
                              {processSchool && <Loading type='1' style={{ color: 'green' }} />}
                              {!processSchool && (
                                <svg
                                  width='22'
                                  height='22'
                                  viewBox='0 0 22 22'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path d='M16.0702 10.028C15.9567 9.78184 15.7068 9.625 15.4319 9.625H12.6261V0.6875C12.6261 0.308086 12.3118 0 11.9247 0H9.11885C8.73174 0 8.4174 0.308086 8.4174 0.6875V9.625H5.6116C5.33672 9.625 5.08683 9.78313 4.97329 10.028C4.85842 10.2743 4.90314 10.5617 5.0842 10.765L9.99435 16.265C10.1276 16.4149 10.3196 16.5 10.5218 16.5C10.7239 16.5 10.9159 16.4132 11.0492 16.265L15.9593 10.765C16.1417 10.5626 16.1838 10.2738 16.0702 10.028Z' />
                                  <path d='M18.2377 15.125V19.25H2.8058V15.125H0V20.625C0 21.3855 0.628674 22 1.4029 22H19.6406C20.4166 22 21.0435 21.3855 21.0435 20.625V15.125H18.2377Z' />
                                </svg>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card_list_turma'>
                      <div className='content_card'>
                        <div className='card_details'>
                          <p className='card_details_label text_uppercase'>Relatório dos alunos </p>
                          <div className='btn_group_card'>
                            <button
                              style={{ background: 'white', border: 'none' }}
                              onClick={(e) => exportToXLS('student')}
                              className='btn_link_card'
                              download='nome do arquivo'
                            >
                              {processStudent && <Loading type='1' style={{ color: 'green' }} />}
                              {!processStudent && (
                                <svg
                                  width='22'
                                  height='22'
                                  viewBox='0 0 22 22'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path d='M16.0702 10.028C15.9567 9.78184 15.7068 9.625 15.4319 9.625H12.6261V0.6875C12.6261 0.308086 12.3118 0 11.9247 0H9.11885C8.73174 0 8.4174 0.308086 8.4174 0.6875V9.625H5.6116C5.33672 9.625 5.08683 9.78313 4.97329 10.028C4.85842 10.2743 4.90314 10.5617 5.0842 10.765L9.99435 16.265C10.1276 16.4149 10.3196 16.5 10.5218 16.5C10.7239 16.5 10.9159 16.4132 11.0492 16.265L15.9593 10.765C16.1417 10.5626 16.1838 10.2738 16.0702 10.028Z' />
                                  <path d='M18.2377 15.125V19.25H2.8058V15.125H0V20.625C0 21.3855 0.628674 22 1.4029 22H19.6406C20.4166 22 21.0435 21.3855 21.0435 20.625V15.125H18.2377Z' />
                                </svg>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card_list_turma'>
                      <div className='content_card'>
                        <div className='card_details'>
                          <p className='card_details_label text_uppercase'>Relatório dos contratos </p>
                          <div className='btn_group_card'>
                            <button
                              style={{ background: 'white', border: 'none' }}
                              onClick={(e) => exportToXLS('contract')}
                              className='btn_link_card'
                              download='nome do arquivo'
                            >
                              {processContract && <Loading type='1' style={{ color: 'green' }} />}
                              {!processContract && (
                                <svg
                                  width='22'
                                  height='22'
                                  viewBox='0 0 22 22'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path d='M16.0702 10.028C15.9567 9.78184 15.7068 9.625 15.4319 9.625H12.6261V0.6875C12.6261 0.308086 12.3118 0 11.9247 0H9.11885C8.73174 0 8.4174 0.308086 8.4174 0.6875V9.625H5.6116C5.33672 9.625 5.08683 9.78313 4.97329 10.028C4.85842 10.2743 4.90314 10.5617 5.0842 10.765L9.99435 16.265C10.1276 16.4149 10.3196 16.5 10.5218 16.5C10.7239 16.5 10.9159 16.4132 11.0492 16.265L15.9593 10.765C16.1417 10.5626 16.1838 10.2738 16.0702 10.028Z' />
                                  <path d='M18.2377 15.125V19.25H2.8058V15.125H0V20.625C0 21.3855 0.628674 22 1.4029 22H19.6406C20.4166 22 21.0435 21.3855 21.0435 20.625V15.125H18.2377Z' />
                                </svg>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card_list_turma'>
                      <div className='content_card'>
                        <div className='card_details'>
                          <p className='card_details_label text_uppercase'>Relatório do financeiro </p>
                          <div className='btn_group_card'>
                            <button
                              style={{ background: 'white', border: 'none' }}
                              onClick={(e) => exportToXLS('financial')}
                              className='btn_link_card'
                              download='nome do arquivo'
                            >
                              {processFinancial && <Loading type='1' style={{ color: 'green' }} />}
                              {!processFinancial && (
                                <svg
                                  width='22'
                                  height='22'
                                  viewBox='0 0 22 22'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path d='M16.0702 10.028C15.9567 9.78184 15.7068 9.625 15.4319 9.625H12.6261V0.6875C12.6261 0.308086 12.3118 0 11.9247 0H9.11885C8.73174 0 8.4174 0.308086 8.4174 0.6875V9.625H5.6116C5.33672 9.625 5.08683 9.78313 4.97329 10.028C4.85842 10.2743 4.90314 10.5617 5.0842 10.765L9.99435 16.265C10.1276 16.4149 10.3196 16.5 10.5218 16.5C10.7239 16.5 10.9159 16.4132 11.0492 16.265L15.9593 10.765C16.1417 10.5626 16.1838 10.2738 16.0702 10.028Z' />
                                  <path d='M18.2377 15.125V19.25H2.8058V15.125H0V20.625C0 21.3855 0.628674 22 1.4029 22H19.6406C20.4166 22 21.0435 21.3855 21.0435 20.625V15.125H18.2377Z' />
                                </svg>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='card_list_turma'>
                      <div className='content_card'>
                        <div className='card_details'>
                          <p className='card_details_label text_uppercase'>Relatório contrato alunos </p>
                          <div className='btn_group_card'>
                            <button
                              style={{ background: 'white', border: 'none' }}
                              onClick={(e) => exportToXLS('contract_students')}
                              className='btn_link_card'
                              download='nome do arquivo'
                            >
                              {processFinancial && <Loading type='1' style={{ color: 'green' }} />}
                              {!processFinancial && (
                                <svg
                                  width='22'
                                  height='22'
                                  viewBox='0 0 22 22'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path d='M16.0702 10.028C15.9567 9.78184 15.7068 9.625 15.4319 9.625H12.6261V0.6875C12.6261 0.308086 12.3118 0 11.9247 0H9.11885C8.73174 0 8.4174 0.308086 8.4174 0.6875V9.625H5.6116C5.33672 9.625 5.08683 9.78313 4.97329 10.028C4.85842 10.2743 4.90314 10.5617 5.0842 10.765L9.99435 16.265C10.1276 16.4149 10.3196 16.5 10.5218 16.5C10.7239 16.5 10.9159 16.4132 11.0492 16.265L15.9593 10.765C16.1417 10.5626 16.1838 10.2738 16.0702 10.028Z' />
                                  <path d='M18.2377 15.125V19.25H2.8058V15.125H0V20.625C0 21.3855 0.628674 22 1.4029 22H19.6406C20.4166 22 21.0435 21.3855 21.0435 20.625V15.125H18.2377Z' />
                                </svg>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Reports;
