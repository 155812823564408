import { getAccountFensorAsaas, getEnvironment } from '../utils/api';
import { PaymentErrors } from '../utils/PaymentErrors';
import { getErrorMessageFromResponse } from '../utils/utils';
import { defaultApiObject, handleFail } from '../utils/utils-api';
import api from './api';
import { PaymentException } from './ExceptionClass/PaymentException';

const enableDebug = (getEnvironment() !== 'PROD');

export async function auth(cnpj, password, loginEmail) {
  try {
    return await api.post('payment-auth', {
      cnpj,
      password,
      ...(loginEmail ? { loginEmail } : undefined),
    });
  } catch (e) {
    return e.message;
  }
}

export async function client(cpf_cnpj, name, email, phone, system_id, user_id, token_assas, customer = '', address) {
  try {
    return await api.post((enableDebug ? 'hmg-' : '') + 'cliente-save', {
      cpf_cnpj,
      name,
      email,
      phone,
      customer,
      system_id,
      user_id,
      token_assas,
      ...address,
    });
  } catch (e) {
    return e.message;
  }
}

export async function payment(url, request) {
  try {
    if (enableDebug) delete request.split;

    return await api.post((enableDebug ? 'hmg-' : '') + url, request);
  } catch (e) {
    return e.message;
  }
}

/**
 *
 * @param {{cnpj: string, password: string, loginEmail?: string}} credentials
 * @returns {object}
 * @throws {PaymentException} PaymentErrors.SERVER_COMMUNICATION_FAILED
 * @throws {PaymentException} PaymentErrors.INVALID_API_AUTH
 */
export async function authClient({ cnpj, password, loginEmail }) {
  const { data, status, connection } = await api.post('payment-auth', {
    cnpj,
    password,
    ...(loginEmail ? { loginEmail } : undefined),
  });

  if (!(status == 200) || !(connection == undefined)) {
    // console.log('Erro ao comunicar com o servidor.');
    throw new PaymentException(PaymentErrors.SERVER_COMMUNICATION_FAILED);
  }

  if (!(data.token != undefined)) {
    // console.log('Autenticação com a API inválida.');
    throw new PaymentException(PaymentErrors.INVALID_API_AUTH);
  }

  return data;
}

/**
 *
 * @param {{cpf_cnpj, name, email, phone, customer, system_id, user_id, token_assas, postalCode, address, addressNumber, complement, province}} clientData
 * @returns {object}
 * @throws {PaymentException} PaymentErrors.UNABLE_SEND_CUSTOMER
 */
export async function saveClient({
  cpf_cnpj,
  name,
  email,
  phone,
  customer,
  system_id,
  user_id,
  token_assas,
  postalCode,
  address,
  addressNumber,
  complement,
  province,
}) {
  const url = enableDebug ? 'hmg-cliente-save' : 'cliente-save';

  const { data, status } = await api.post(url, {
    cpf_cnpj,
    name,
    email,
    phone,
    customer,
    system_id,
    user_id,
    token_assas,
    postalCode,
    address,
    addressNumber,
    complement,
    province,
  });

  if (status == 200 && Array.isArray(data.message)) {
    const error = new PaymentException(PaymentErrors.CUSTOMER_VALIDATION_ERROR);
    error.auxData = [
      'Erro ao validar as informações do cliente.',
      ...data.message
        .map(({ field, message }) => {
          switch (field) {
            case 'email':
              return `- Endereço de e-mail inválido: ${email}`;

            case 'phone':
              return `- Telefone inválido: ${phone}`;

            case 'address':
              return `- Endereço maior do que 55 caracteres: ${[
                postalCode,
                address,
                addressNumber,
                complement,
                province,
              ]
                .filter((f) => f)
                .join(', ')}`;

            default:
              return `- ${message}`;
          }
        })
        .filter((f) => f),
    ].join('\n');
    throw error;
  } else if (!(status == 200 && typeof data.message === 'string')) {
    // console.log('Nao foi possível enviar o cliente.', data.message);
    throw new PaymentException(PaymentErrors.UNABLE_SEND_CUSTOMER);
  }

  return data;
}

/**
 *
 * @param {{paymentMethod:'CREDIT_CARD' | 'BOLETO', paymentType: 'A_VISTA' | 'PARCELADO', billingData: object}} billData
 * @returns {object}
 * @throws {PaymentException} PaymentErrors.BILL_STATUS_ERROR
 * @throws {PaymentException} PaymentErrors.BILL_ERROR
 * @throws {PaymentException} PaymentErrors.UNABLE_VALIDATE_PAYMENT
 */
export async function sendBilling({ paymentMethod, paymentType, billingData }) {
  const url = enableDebug
    ? {
        A_VISTA: { CREDIT_CARD: 'hmg-payment-card-cred-vista-invoice-url', BOLETO: 'hmg-payment-boleto-vista' },
        PARCELADO: {
          CREDIT_CARD: 'hmg-payment-card-cred-parcelado-invoice-url',
          BOLETO: 'hmg-payment-boleto-parcelado',
        },
      }
    : {
        A_VISTA: { CREDIT_CARD: 'payment-card-cred-vista-invoice-url', BOLETO: 'payment-boleto-vista' },
        PARCELADO: { CREDIT_CARD: 'payment-card-cred-parcelado-invoice-url', BOLETO: 'payment-boleto-parcelado' },
      };

  if (enableDebug) delete billingData.split;

  const { data } = await api.post(url[paymentType][paymentMethod], billingData);

  if (data.paymentResponse != undefined) {
    if (data.request.status == 'CONFIRMED' || data.request.status == 'PENDING') {
      return data;
    } else {
      throw new PaymentException(PaymentErrors.BILL_STATUS_ERROR);
    }
  } else if (data.message != undefined) {
    const message = getErrorMessageFromResponse(data.data.message);
    throw new PaymentException(PaymentErrors.BILL_ERROR, message);
  } else if (data.erro != undefined) {
    // console.log('Nao foi possível validar o pagamento.');
    throw new PaymentException(PaymentErrors.UNABLE_VALIDATE_PAYMENT);
  }
}

/**
 * @param {{ idAsaas, token_assas, financial_id }} billData
 * @returns {object}
 * @throws {PaymentException} PaymentErrors.PROCESSING_ERROR
 */
export async function cancelBillingAndDelete({ idAsaas, token_assas, financial_id }) {
  try {
    const url = enableDebug ? 'hmg-delete-payment-e-financial-school' : 'delete-payment-e-financial-school';
    const { data } = await api.post(url, { idAsaas, token_assas, financial_id });

    if (data.deleted) return data;
    else throw new PaymentException(PaymentErrors.PROCESSING_ERROR);
  } catch (error) {
    throw new PaymentException(PaymentErrors.PROCESSING_ERROR);
  }
}

/**
 *
 * @param {{paymentMethod:'CREDIT_CARD' | 'BOLETO', billingData: object}} billData
 * @returns {object}
 * @throws {PaymentException} PaymentErrors.SUBSCRIBE_STATUS_ERROR
 * @throws {PaymentException} PaymentErrors.SUBSCRIBE_ERROR
 * @throws {PaymentException} PaymentErrors.UNABLE_VALIDATE_PAYMENT
 */
export async function sendSubscribe({ paymentMethod, billingData }) {
  const url = enableDebug
    ? { CREDIT_CARD: 'hmg-payment-cart-cred-assinatura-invoice-url', BOLETO: 'hmg-payment-boleto-assinatura' }
    : { CREDIT_CARD: 'payment-cart-cred-assinatura-invoice-url', BOLETO: 'payment-boleto-assinatura' };

  if (enableDebug) delete billingData.split;

  const { data } = await api.post(url[paymentMethod], billingData);

  if (data.data.paymentResponse != undefined && data.data.paymentResponse.status == 'ACTIVE') {
    return data.data;
  } else if (data.data.paymentResponse != undefined && data.data.paymentResponse.status != 'ACTIVE') {
    throw new PaymentException(PaymentErrors.SUBSCRIBE_STATUS_ERROR);
  } else if (data.data.message != undefined) {
    const message = getErrorMessageFromResponse(data.data.message);
    throw new PaymentException(PaymentErrors.SUBSCRIBE_ERROR, message);
  } else if (data.data.erro != undefined) {
    // console.log('Nao foi possível validar o pagamento.');
    throw new PaymentException(PaymentErrors.UNABLE_VALIDATE_PAYMENT);
  }
}

/**
 *
 * @param {{ idAsaas, token_assas, contract_id, contract_type: 'SCHOOL'|'CLUB', mustCancelContract?: boolean }} billData
 * @returns {object}
 * @throws {PaymentException} PaymentErrors.PROCESSING_ERROR
 */
export async function cancelSubscribe({
  idAsaas,
  token_assas,
  contract_id,
  contract_type = 'SCHOOL',
  mustCancelContract,
}) {
  const url = enableDebug ? 'hmg-payment-cancelar-assinatura' : 'payment-cancelar-assinatura';
  const { data } = await api.post(url, { idAsaas, token_assas, contract_id, contract_type, mustCancelContract });

  if (data.data.status === 'ERROR') {
    return { status: 'ERROR', messageKey: data.data.messageKey, message: data.data.message };
  } else if (data.data.status === 'WARNING') {
    return { status: 'WARNING', messageKey: data.data.messageKey, message: data.data.message };
  } else if (data.data.deleted) {
    return { ...data.data, status: 'SUCCESS' };
  } else {
    return { ...data.data, status: 'FAIL' };
  }
}

/**
 *
 * @param {object} billingData
 * @returns {object}
 * @throws {PaymentException} PaymentErrors.MONEY_SUBSCRIBE_ERROR
 */
export async function sendMoneySubscribe(billingData) {
  try {
    const url = enableDebug ? 'hmg-payment-dinheiro-assinatura' : 'payment-dinheiro-assinatura';
    const { data } = await api.post(url, billingData);

    if (data.success) return data;
    else {
      // console.log('Nao foi possivel validar o pagamento.');
      // console.log(data.message);
      throw new PaymentException(PaymentErrors.MONEY_SUBSCRIBE_ERROR);
    }
  } catch (error) {
    throw new PaymentException(error);
  }
}

/**
 * @param {{ contract_id, contract_type: 'SCHOOL'|'CLUB', mustCancelContract?: boolean }} billData
 * @returns {object}
 * @throws {PaymentException} PaymentErrors.PROCESSING_ERROR
 */
export async function cancelMoneySubscribe({ contract_id, contract_type = 'SCHOOL', mustCancelContract }) {
  try {
    const url = enableDebug ? 'hmg-payment-dinheiro-cancelar-assinatura' : 'payment-dinheiro-cancelar-assinatura';
    const { data } = await api.post(url, {
      contract_id,
      contract_type,
      mustCancelContract,
    });

    if (data.success) {
      return data;
    } else {
      // console.log(data.message);
      throw new PaymentException(PaymentErrors.PROCESSING_ERROR);
    }
  } catch (error) {
    // console.log(error.message);
    throw new PaymentException(PaymentErrors.PROCESSING_ERROR);
  }
}

export function getAsaasConfigSessionSchool(schoolSession, companySession) {
  if (enableDebug) {
    return {
      ...getAccountFensorAsaas(),
      utilizaFaturamento: schoolSession.schUtilizaFaturamento
    }
  }
    
  const configAsaas = {
    cnpj: schoolSession.schCnpjAsaas,
    password: schoolSession.schSenha,
    loginEmail: schoolSession.schLoginEmailAsaas,
    // user_id
    token_assas: schoolSession.schToken,
    wallet: schoolSession.schWalletID,
    utilizaFaturamento: schoolSession.schUtilizaFaturamento,
    system_id: getAccountFensorAsaas().system_id,
    user_id: getAccountFensorAsaas().user_id,
  };

  if (companySession.comPercentual) {
    configAsaas.percentual = parseFloat(companySession.comPercentual);
  } else if (companySession.comValor) {
    configAsaas.valor = parseFloat(companySession.comValor);
  } else {
    configAsaas.percentual = null;
  }

  return configAsaas;
}

export function getAsaasConfigSessionClub(companySession) {
  if (enableDebug) {
    return {
      ...getAccountFensorAsaas(),
      utilizaFaturamento: companySession.comCnpjAsaas && companySession.comToken ? 'S' : 'N'
    }
  }

  const configAsaas = {
    cnpj: companySession.comCnpjAsaas,
    password: companySession.comSenha,
    loginEmail: companySession.comLoginEmailAsaas,
    // user_id
    token_assas: companySession.comToken,
    wallet: companySession.comWalletID,
    percentual: companySession.comPercentual ? parseFloat(companySession.comPercentual) : null,
    valor: companySession.comValor ? parseFloat(companySession.comValor) : null,
    system_id: getAccountFensorAsaas().system_id, // 3 - HMG, 1 - FENSOR
    user_id: getAccountFensorAsaas().user_id,
  };

  if (companySession.comPercentual) {
    configAsaas.percentual = parseFloat(companySession.comPercentual);
  } else if (companySession.comValor) {
    configAsaas.valor = parseFloat(companySession.comValor);
  } else {
    configAsaas.percentual = null;
  }

  return configAsaas;
}

export async function receiveInCash({ id, idAsaas, paymentDate, value, token_assas, observation }) {
  try {
    const url = enableDebug ? 'hmg-payment-recebimento-dinheiro' : 'payment-recebimento-dinheiro';

    const { data } = await api.post(url, { id, idAsaas, paymentDate, value, token_assas, observation });

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
}

/**
 *
 * @param {object} billingData
 * @returns {object}
 * @throws {PaymentException} PaymentErrors.MONEY_PAYMENT_ERROR
 */
export async function sendMoneyProducts(billingData) {
  try {
    const { data } = await api.post('payment-dinheiro-produtos', billingData);

    if (data.success) return data;
    else {
      throw new PaymentException(PaymentErrors.MONEY_PAYMENT_ERROR);
    }
  } catch (error) {
    throw new PaymentException(error);
  }
}

export async function cancelOrder({ orderId }) {
  try {
    const url = enableDebug ? 'hmg-cancel-order' : 'cancel-order';

    const { data } = await api.post(url, { order_id: orderId });

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
}
